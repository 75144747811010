import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchProgramsIfNeeded } from 'actions/programs';
import { fetchFactsIfNeeded } from 'actions/facts';
import { fetchModule, updateModule, resetModule, getContentIfNeeded } from 'actions/module';
import { push } from 'connected-react-router';
import { EditModule } from 'views';

const mapStateToProps = (state, ownProps) => {
  return ({
    programs: state.programs,
    programModule: state.module,
    facts: state.facts,
    content: state.module.content,
    programModuleId: ownProps.match.params.id,
  })
};

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        fetchModule,
        updateModule,
        getContent: getContentIfNeeded,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchProgramsIfNeeded());
      dispatch(fetchFactsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetModule());
    },
    querying: () => {
      dispatch(getContentIfNeeded());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModule);
