import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  InfoOutlined as InfoIcon,
} from '@material-ui/icons';

import useStyles from '../Question.styles';


const QuestionOptions = ({ open, onClose, onChange, question, questionKey }) => {
  const classes = useStyles();

  const addDisplayCondition = () => {
    const newDisplayConditions = [
      ...question.displayConditions,
      {
        questionName: '',
        answerIndices: '',
      },
    ];
    onChange('displayConditions')({ target: { value: newDisplayConditions } });
  };

  const deleteDisplayCondition = (deleteKey) => () => {
    const newDisplayConditions = question.displayConditions.filter(
      (_, conditionKey) => conditionKey !== deleteKey
    );
    onChange('displayConditions')({ target: { value: newDisplayConditions } });
  };

  const renderDisplayConditions = (condition, conditionKey) => (
    <Grid key={conditionKey} container spacing={2}>
      <Grid item xs={12} sm={5}>
        <TextField
          autoFocus
          margin="dense"
          label="Question name"
          type="text"
          value={condition.questionName}
          onChange={onChange(`displayConditions[${conditionKey}].questionName`)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title="Question name the condition will be based on."
                  placement="top-start"
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          autoFocus
          margin="dense"
          label="Answer indices"
          type="text"
          value={condition.answerIndices}
          onChange={onChange(`displayConditions[${conditionKey}].answerIndices`)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title="Indices (positions) of the answers which will make the condition true,
                   therefore making this question the next to be shown. e.g: 1, 2, 3"
                  placement="top-start"
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={1} className={classes.buttonContainer}>
        <IconButton onClick={deleteDisplayCondition(conditionKey)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  return (
    <Dialog key={questionKey} open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{`Aditional Settings Question ${question.name}`}</DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Extra Fields
        </Typography>

        <Typography variant="h6" gutterBottom>
          Display Conditions
        </Typography>
        <Typography variant="body2" gutterBottom>
          These are used for branching inside the survey, based on answers given in previous
          questions.
        </Typography>
        {question.displayConditions?.map(renderDisplayConditions)}
      </DialogContent>
      <DialogActions>
        <Button onClick={addDisplayCondition} color="primary">
          Add display condition
        </Button>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionOptions;