import * as data from 'data/pledges';
import * as service from 'services/pledges';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';

const requestPledge = (id) => ({
  type: data.TYPE_REQUEST_PLEDGE,
  pledge: { id },
});

const receivePledge = (json) => ({
  type: data.TYPE_RECEIVE_PLEDGE,
  pledge: json.data,
  lastUpdated: json.meta.time,
  testPassed: json.meta.tests,
  testResults: isEmpty(json.tests) ? [] : json.tests.conditions
});

const receivePledgeError = (error) => ({
  type: data.TYPE_RECEIVE_PLEDGE_ERROR,
  error,
});

const postUpdatePledge = (pledge) => ({
  type: data.TYPE_UPDATE_PLEDGE,
  pledge,
});

const postUpdatePledgeSuccess = (pledge, json) => ({
  type: data.TYPE_UPDATE_PLEDGE_SUCCESS,
  lastUpdated: json.meta.time,
  tenants_conflicts: json.tenants_conflicts,
  pledge,
  testPassed: json.meta.tests,
  testResults: isEmpty(json.tests) ? [] : json.tests.conditions
});

const postUpdatePledgeError = (pledge, error) => ({
  type: data.TYPE_UPDATE_PLEDGE_ERROR,
  error,
  pledge,
});

const postCreatePledge = (pledge) => ({
  type: data.TYPE_CREATE_PLEDGE,
  pledge,
});

const postCreatePledgeSuccess = (pledge, json) => ({
  type: data.TYPE_CREATE_PLEDGE_SUCCESS,
  lastUpdated: json.meta.time,
  pledge,
});

const postCreatePledgeError = (pledge, error) => ({
  type: data.TYPE_CREATE_PLEDGE_ERROR,
  error,
  pledge,
});

const requestLanguageFile = () => ({
  type: data.TYPE_REQUEST_PLEDGE_LANGUAGE_FILE
});

const saveLanguageFileSuccess = () => ({
  type: data.TYPE_POST_PLEDGE_LANGUAGE_FILE_SUCCESS
});

const saveLanguageFileError = () => ({
  type: data.TYPE_POST_PLEDGE_LANGUAGE_FILE_ERROR
});

export const resetPledge = () => ({
  type: data.TYPE_RESET_PLEDGE,
});

const postLanguageFile = () => ({
  type: data.TYPE_POST_PLEDGE_LANGUAGE_FILE
});

export const fetchPledge = (id) => (dispatch) => {
  dispatch(requestPledge(id));
  return service
    .fetchPledge(id)
    .then((pledge) => dispatch(receivePledge(pledge)))
    .catch((reason) => dispatch(receivePledgeError(reason)));
};

export const updatePledge = (pledge) => (dispatch) => {
  dispatch(postUpdatePledge(pledge));
  return service
    .updatePledge(pledge)
    .then((response) => {
      dispatch(postUpdatePledgeSuccess(pledge, response));
    })
    .catch((reason) => dispatch(postUpdatePledgeError(pledge, reason)));
};

export const createPledge = (pledge) => (dispatch) => {
  dispatch(postCreatePledge(pledge));
  return service
    .createPledge(pledge)
    .then((response) => {
      dispatch(postCreatePledgeSuccess(pledge, response));
      dispatch(push(`${response.data.id}/edit`));
    })
    .catch((reason) => dispatch(postCreatePledgeError(pledge, reason)));
};

export const getLanguageFile = (pledgeId, languageCode) => {
  return async dispatch => {
    dispatch(requestLanguageFile());
    const result = await service.getLanguageFile(pledgeId, languageCode);
    return result;
  };
};

export const getLanguageFileTemplate = (pledgeId) => {
  return async dispatch => {
    dispatch(requestLanguageFile());
    const result = await service.getLanguageFileTemplate(pledgeId);
    return result;
  };
};

export const saveLanguageFile = (file, pledgeId, languageCode) => {
  return async dispatch => {
    dispatch(postLanguageFile());
    return service
      .postLanguageFile(file, pledgeId, languageCode)
      .then(() => dispatch(saveLanguageFileSuccess()))
      .catch(() => dispatch(saveLanguageFileError()));
  };
};
