import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  card: {
    padding: '1rem 0.5rem',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  textContainer: {
    flexShrink: '2',
    width: '80%',
    padding: '0 0.5rem',
  },
  text: {
    marginTop: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  cardExpanded: {
    transform: 'rotate(180deg)'
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardActions: {
    width: 'fit-content',
    alignSelf: 'flex-end'
  },
  fieldName: {
    fontWeight: theme.typography.fontWeightBold
  },
}));
