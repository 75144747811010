import React from 'react'

export const RoundedButton = ({text, action, variant, borderColor}) => {
    return (
      <button
        className={[
          'mr-2 border-3 rounded-3xl py-2 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-opacity-50 font-bold capitalize',
          variant ? 'w-full' : 'w-1/2',
          borderColor ? 'border-light-grayish-orange-2' : 'border-gray-600',
        ].join(' ')}
        onClick={action}
      >
        <div className="font-gotham">{text}</div>
      </button>
    );
}

export default RoundedButton
