import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  addQuestionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 4)
  },
  li : {
    listStyleType: 'none'
  },
  ul : {
    listStyleType: 'none'
  }
}));
