import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createModule, getContentIfNeeded } from 'actions/module';
import { CreateModule } from 'views';
import { push } from 'connected-react-router';
import { fetchProgram } from 'actions/program';
import { fetchFactsIfNeeded } from 'actions/facts';

const mapStateToProps = (state, ownProps) => ({
  programs: state.programs,
  program: state.program,
  facts: state.facts,
  content: state.module.content,
  programId: ownProps.match.params.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        createModule,
        getContent: getContentIfNeeded,
        fetchProgram,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchFactsIfNeeded());
    },
    querying: () => {
      dispatch(getContentIfNeeded());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateModule);
