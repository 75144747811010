import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  form: {
    marginBottom: theme.spacing(2)
  },
  tabs: {
    marginTop: theme.spacing(2)
  },
  conflictLink: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    marginRight: '0.25rem',
  },
  conflictContainer: {
    margin: '0 0 1rem'
  },
  conflictIcon: {
    color: 'rgba(225, 196, 66)',
  },
  conflictText: {
    padding: '0 1rem'
  },
  errorItems: {
    width: '100%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
  previewButton: {
    marginLeft: '8px'
  }
}));
