export const TYPE_REQUEST_SURVEYS = 'surveys/REQUEST_SURVEYS';
export const TYPE_RECEIVE_SURVEYS = 'surveys/RECEIVE_SURVEYS';
export const TYPE_RECEIVE_SURVEYS_CACHED = 'surveys/RECEIVE_SURVEYS-CACHED';
export const TYPE_RECEIVE_SURVEYS_ERROR = 'surveys/RECEIVE_SURVEYS_ERROR';

export const TYPE_REQUEST_SURVEY = 'survey/REQUEST_SURVEY';
export const TYPE_RECEIVE_SURVEY = 'survey/RECEIVE_SURVEY';
export const TYPE_RECEIVE_SURVEY_ERROR = 'survey/RECEIVE_SURVEY_ERROR';
export const TYPE_UPDATE_SURVEY = 'survey/UPDATE_SURVEY';
export const TYPE_UPDATE_SURVEY_SUCCESS = 'survey/UPDATE_SURVEY_SUCCESS';
export const TYPE_UPDATE_SURVEY_ERROR = 'survey/UPDATE_SURVEY_ERROR';
export const TYPE_CREATE_SURVEY = 'survey/CREATE_SURVEY';
export const TYPE_CREATE_SURVEY_SUCCESS = 'survey/CREATE_SURVEY_SUCCESS';
export const TYPE_CREATE_SURVEY_ERROR = 'survey/CREATE_SURVEY_ERROR';
export const TYPE_RESET_SURVEY = 'survey/RESET_SURVEY';
export const TYPE_REQUEST_LANGUAGE_FILE = 'survey/REQUEST_LANGUAGE_FILE';
export const TYPE_POST_SURVEY_LANGUAGE_FILE = 'survey/POST_SURVEY_LANGUAGE_FILE';
export const TYPE_POST_SURVEY_LANGUAGE_FILE_SUCCESS = 'survey/POST_SURVEY_LANGUAGE_FILE_SUCCESS';
export const TYPE_POST_SURVEY_LANGUAGE_FILE_ERROR = 'survey/POST_SURVEY_LANGUAGE_FILE_ERROR';

export const SURVEY_QUESTION_TYPES = [
  { value: '0', text: 'Single answer' },
  { value: '1', text: 'Custom answer' },
  //{ value: '2', text: 'Long text' },
  { value: '3', text: 'Multiple answer' },
];

export const SURVEY_OPTION_INPUT_TYPES = [
  { value: 'keypad', text: 'Keypad' },
  { value: 'time', text: 'Time' },
  { value: 'picker', text: 'Picker' },
  { value: 'text', text: 'Text' },
  { value: 'date', text: 'Date' },
];

export const SURVEY_TYPES = [
  { value: '0', text: 'Baselining' },
  { value: '1', text: 'Hypertension' },
  { value: '2', text: 'Sleep' },
  { value: '3', text: 'Arrhythmia' },
  { value: '4', text: 'Heart' },
  { value: '5', text: 'Diabetes' },
  { value: '6', text: 'Ad Hoc' },
  { value: '7', text: 'Review' },
  { value: '8', text: 'Single Question' },
];

export const SURVEY_STATE = [
  { value: '0', text: 'Draft' },
  { value: '1', text: 'Review' },
  { value: '2', text: 'Prerelease' },
  { value: '3', text: 'Live' },
  { value: '4', text: 'Deprecated' },
];

export const SURVEY_GENDER_CONDITIONS = [
  { value: 'default', text: 'None' },
  { value: 'female_only', text: 'Only female' },
  { value: 'male_only', text: 'Only male' },
];

export const SURVEY_TRIGGER_WAYS = [
  { value: 'condition_day', text: 'Condition day' },
  { value: 'rules_based', text: 'Rules based' },
  //{ value: 'physician_triggered', text: 'Triggered by physician' },
  //{ value: 'bayes', text: 'Bayes' }
];

export const NEW_SURVEY_QUESTION_OPTION = {
  inputType: 'default',
  nextQuestion: '',
  position: 1,
  text: 'Option text',
  value: '',
};

export const NEW_SURVEY_QUESTION = {
  name: '',
  type: '0',
  text: 'Untitled question',
  position: 1,
  options: [],
  defaultNextQuestion: 'outro',
  displayConditions: [],
};

export const PRIORITY_LIMIT_EXCEEDED = 'Priority must be 1-200, the current value is out of the expected range.';
