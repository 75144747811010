import React, { useState, useEffect } from 'react';
import { RoundedButton } from 'components/commons';
import LongCardLayout from 'components/views/mobile-layout/long-card-layout';

import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';
import { CircularProgress, Box } from '@material-ui/core';

export const SurveyCard = ({ title = '', questions = [], body = '' }) => {
  const [surveyState, setSurveyState] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const handleSurveyState = () => {
    setSurveyState(!surveyState);
  };
  if(loading){
    return <Box sx={{ display: 'flex', position: 'absolute', top: '50%', left : "47%" }}>
    <CircularProgress />
  </Box>
  }
  const selectedDevice = document.getElementsByClassName("_1HaoU")[0].innerHTML;
  const dynamicClass = (selectedDevice === "Note 8" || selectedDevice === "Nexus 5" ) ? "text-cardTitle font-black font-gotham" : "text-cardTitle font-black font-gotham line-clamp-3";
  
  return (
    <>
      <div className="flex flex-col rounded-2xl shadow-lg mx-3 p-6 bg-white">
        <div className="flex flex-row items-center">
          <div className="mr-2">
            <EventNoteRoundedIcon className="text-soft-red" fontSize="large" />
          </div>
          <div className="flex flex-col">
            <div className="font-gotham">Smart Coach</div>
            <div className="font-bold font-gotham">Questions: {questions?.length}</div>
          </div>
        </div>
        <div className="mt-6">
          <div className={dynamicClass}>{title}</div>
        </div>
        <div className="mt-3 text-cardBody text-left font-gotham pr-2">{body}</div>
        <div className="mt-14 flex flex-row justify-center">
          {!surveyState ? (
            <RoundedButton text={'Take The Survey'} variant={true} action={handleSurveyState} />
          ) : (
            <RoundedButton text={'Begin'} variant={true} action={handleSurveyState} />
          )}
        </div>
      </div>
      <LongCardLayout
        show={surveyState}
        setShow={handleSurveyState}
        questions={questions}
        title={title}
        body={body}
      />
    </>
  );
};

export default SurveyCard;
