import * as service from 'services/modules';
import * as data from 'data/modules';

const requestModules = () => ({
  type: data.TYPE_REQUEST_MODULES,
  isFetching: true
});

const receiveModules = json => ({
  type: data.TYPE_RECEIVE_MODULES,
  isFetching: false,
  modules: json.modules
});

const receiveModulesError = error => ({
  type: data.TYPE_RECEIVE_MODULES_ERROR,
  isFetching: false,
  error: error
});

export const fetchModules = () => dispatch => {
  dispatch(requestModules());
  return service
    .fetchModules()
    .then(modules => dispatch(receiveModules(modules)))
    .catch(error => dispatch(receiveModulesError(error)));
};

export default fetchModules;