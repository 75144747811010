import Form from 'components/commons/form';
import Select from 'components/commons/select';
import Button from 'components/commons/button';
import React from 'react';
import useStyles from './TenantsIdSelector.styles';

const TenantsIdSelector = ({ handleChange, onSubmit, selectedTenant, tenants }) => {
  const classes = useStyles();

  const handleSubmit = () => {
    onSubmit();
  };

  const TENANT_OPTIONS = tenants.map(tenant => ({ value: tenant.id, text: tenant.name }));

  return (
    <Form onSubmit={handleSubmit} className={classes.select} >
      <Select
        label="Tenant"
        id="tenant"
        onChange={(e) => handleChange(e)}
        value={selectedTenant}
        options={TENANT_OPTIONS}
        fullWidth
        required
      />
      <Button color="primary" variant="contained" type="submit" size="medium">
        Select tenant
            </Button>
    </Form>
  );
}

export default TenantsIdSelector;