import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import useStyles from './AdditionalConditions.styles';

const AdditionalConditions = ({ condition, value, handlers }) => {
  const classes = useStyles();
  if (condition.type === "checkbox") {
    // TODO: handler implementation
    return (<FormControl component="fieldset">
      <FormLabel component="legend">{condition.label}</FormLabel>
      <FormGroup>
        {condition.options.map((option, index) => {
          return (<FormControlLabel
            key={index}
            control={<Checkbox
              // checked={gilad}
              //  onChange={handleChange}
              name="gilad" />}
            label={option.label}
          />);
        })}
      </FormGroup>
    </FormControl>);
  } else if (condition.type === "radio-button") {
    return (<FormControl component="fieldset">
      <FormLabel component="legend">{condition.label}</FormLabel>
      <RadioGroup aria-label={condition.value} name={condition.value} value={value}
        onClick={(e) => {
          handlers.handleRadioButtonChange(e);
          e.stopPropagation();
        }}
        className={classes.radioGroup}
      >
        {condition.options.map((option, index) => {
          return <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.label} />;
        })}
      </RadioGroup>
    </FormControl>);
  }
};

export default AdditionalConditions;