import TestFactory from './tests';
import { cleanConditions } from "./utils";

export default class ProgramFactory {
  static toDTO(model) {
    return {
      name: model.name,
      id: model.id,
      content_identifier: model.contentIdentifier,
      status: model.status,
      tests: TestFactory.testToDTO(model.tests),
      conditions: cleanConditions(model.conditions),
      edges: model.edges.map(ProgramFactory.edgeToDTO),
      modules: model.modules.map(ProgramFactory.moduleToDTO),
      tenants: model.tenants || [],
      exclude_tenants: model.excludeTenants || [],
      cooldown: parseInt(model.cooldown),
      ttl: parseInt(model.ttl),
      visual_conditions: model.visualConditions,
      ab_test_name: model.abTestName,
      ab_test_side: model.abTestSide,
      group_identifier: model.groupIdentifier,
      group_name: model.groupName,
      group_name_cooldown: model.groupNameCooldown,
    };
  }

  static toNewProgramDTO(model) {
    return {
      name: model.name,
      status: 'draft',
      modules: [],
      edges: [],
      conditions: cleanConditions(model.conditions),
      tests: TestFactory.testToDTO(model.tests),
      tenants: model.tenants,
      exclude_tenants: model.excludeTenants,
      cooldown: parseInt(model.cooldown),
      ttl: parseInt(model.ttl),
      visual_conditions: model.visualConditions
    };
  }

  static moduleToDTO(moduleModel) {
    return {
      id: moduleModel.id,
      name: moduleModel.name,
      content_identifier: moduleModel.contentIdentifier,
    };
  }

  static edgeToDTO(edgeModel) {
    if (edgeModel.src)
      return {
        conditions: cleanConditions(edgeModel.conditions) ?? [],
        delay: edgeModel.delay,
        dst: edgeModel.dst,
        src: edgeModel.src,
        tests: edgeModel.tests != null ? TestFactory.testToDTO(edgeModel.tests) : { conditions: [] },
        visual_conditions: edgeModel.visualConditions
      };
    return {
      dst: edgeModel.dst,
    };
  }

  static toModel(dto) {
    return {
      id: dto.id,
      contentIdentifier: dto.content_identifier,
      name: dto.name,
      status: dto.status,
      modules: dto.modules?.map(ProgramFactory.moduleToModel),
      conditions: dto.conditions,
      tests: TestFactory.testToModel(dto.tests),
      edges: dto.edges?.length > 0 ? dto.edges.map(ProgramFactory.edgeToModel) : [],
      tenants: dto.tenants,
      excludeTenants: dto.exclude_tenants,
      cooldown: dto.cooldown?.toString() ?? '',
      ttl: dto.ttl?.toString() ?? '',
      visualConditions: dto.visual_conditions,
      abTestName: dto.ab_test_name,
      abTestSide: dto.ab_test_side,
      groupIdentifier: dto.group_identifier,
      groupName: dto.group_name,
      groupNameCooldown: dto.group_name_cooldown ?? '',
    };
  }

  static moduleToModel(moduleDTO) {
    return {
      contentIdentifier: moduleDTO.content_identifier,
      name: moduleDTO.name,
      id: moduleDTO.id,
      steps: moduleDTO.steps,
    };
  }

  static contentToModel(dto) {
    return {
      contentIdentifier: dto.content_identifier,
      name: dto.name,
      steps: dto.steps,
      id: dto.id,
    };
  }

  static edgeToModel(edgeDTO) {
    return {
      conditions: edgeDTO.conditions,
      delay: edgeDTO.delay,
      dst: edgeDTO.dst,
      src: edgeDTO.src,
      tests: edgeDTO.tests != null ? TestFactory.testToModel(edgeDTO.tests) : { conditions: [] },
      visualConditions: edgeDTO.visual_conditions || ""
    };
  }

  static testToModel(testDTO) {
    return {
      conditions: testDTO.conditions?.map((item) => ({
        ...item,
        facts: item.facts != null ? Object.entries(item.facts) : [],
      })),
    };
  }
}
