import React, { useEffect, useState } from 'react';
import BlocklyComponent, { Block } from '../blockly';
import { union, remove } from 'lodash';
import '@blockly/block-plus-minus';
import { Button, Typography } from '@material-ui/core';

import BlocklyJS from 'blockly/javascript';
import BlocklyPY from 'blockly/python';
import './blocks/customblocks';
import './generator/generator';
import Autocomplete from 'components/commons/autocomplete';

import useStyles from './Blockly-conditions.styles';

const BlocklyConditions = ({ handleChange, visualConditions, factOptions = [], updateOptions = false, setUpdateOptions, validating, setCodeToValidate = () => { } }) => {
  const classes = useStyles();
  const [code, setCode] = useState([]);
  const [error, setError] = useState('');
  const [facts, setFacts] = useState([]);
  const [initFacts, setInitFacts] = useState([]);
  const [simpleWorkspace, setSimpleWorkspace] = useState({ workspace: null, XML: '', update: 0 });

  useEffect(() => {
    if (initFacts.length) {
      const blocks = simpleWorkspace.workspace.getAllBlocks();
      const factsBlocks = blocks.filter(block => {
        return block.type.includes("facts");
      });
      factsBlocks.forEach((fb, index) => {
        var drop = fb.getField("fact");
        drop.menuGenerator_ = facts.map(f => [f, f]);
        drop.doValueUpdate_(initFacts[index]);
        drop.renderSelectedText_(initFacts[index]);
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simpleWorkspace.workspace]);

  useEffect(() => {
    if (updateOptions) {
      changeMenuOptions(facts);
      setUpdateOptions(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOptions]);

  useEffect(() => {
    if (validating) {
      const newCode = getJSCode();
      setCodeToValidate(newCode);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validating]);

  useEffect(() => {
    var xmlFacts = [];

    if (visualConditions) {
      const parser = new DOMParser();
      //Parse string to XML to use getElementByTag Function
      const xmlVisualConditions = parser.parseFromString(visualConditions, "text/xml");
      //Get Field Tags
      const fields = Array.prototype.slice.call(xmlVisualConditions.getElementsByTagName("field"));
      //Filter all facts
      xmlFacts = fields.filter(field => {
        return field.getAttribute('name') === 'fact';
      });
      //Get Fact Value
      xmlFacts = xmlFacts.map(fact => {
        return fact.textContent;
      });
    }
    xmlFacts.push("account_age_day");
    setInitFacts(xmlFacts);
    setFacts([...new Set(xmlFacts)]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualConditions]);

  const getJSCode = () => {
    try {
      var newCode = BlocklyJS.workspaceToCode(
        simpleWorkspace.workspace
      );
      if (!newCode) {
        return;
      }
      const initFacts = initializate(removeUserCohort(newCode).match(/(\?[a-z]*)\w+/g) || []);
      const newCodeSplit = union(initFacts, newCode.trim().split(';').filter(code => code !== ""));
      return newCodeSplit.map(code => code.trim());
    } catch (error) {
      console.error(error);
    }
  };

  const generateCode = () => {
    try {
      setSimpleWorkspace({ ...simpleWorkspace, update: simpleWorkspace.update + 1 });
      setError('');
      var newCode = BlocklyPY.workspaceToCode(
        simpleWorkspace.workspace
      );
      if (!newCode) {
        handleChange(code, null, null, true);
        return;
      }
      const initFacts = initializate(removeUserCohort(newCode, "py").match(/(\?[a-z]*)\w+/g) || []);
      const newCodeSplit = union(initFacts, newCode.split('\n')).filter(code => code !== "");
      handleChange(newCodeSplit, null, null);
      setCode(newCodeSplit);
    } catch (error) {
      if (error.code === 'USER_COHORT_ERROR') {
        setError(error.message);
      } else {
        setError('Sintax Error: Please review your code');
      }
    }
  };

  const initializate = (facts) => {
    remove(facts, function (fact) {
      return ['?last_step_sub_status'].includes(fact);
    });
    return facts.map(fact => {
      return `(${fact.substring(1)} ${fact})`;
    });
  };

  const removeUserCohort = (conditions, lang = 'js') => {
    var splited = conditions.trim().split(';').filter(code => code !== "");
    if (lang === 'py') {
      splited = conditions.trim().split('\n').filter(code => code !== "");
    }
    remove(splited, function (line) {
      return line.includes("user_cohort");
    });
    return splited.toString();
  };

  const changeMenuOptions = (options) => {
    if (simpleWorkspace.workspace) {
      const blocks = simpleWorkspace.workspace.getAllBlocks();
      const factsBlocks = blocks.filter(block => {
        return block.type.includes("facts");
      });
      const labels = [];
      var factsWithType = factOptions.filter(fact => {
        if (options.includes(fact.label)) {
          labels.push(fact.label);
          return true;
        }
        return false;
      });
      // deleting duplicates
      factsWithType = [...new Set(factsWithType.map(JSON.stringify))].map(JSON.parse);
      const missingOptions = options.filter(opt => !labels.includes(opt)).map(opt => ({ label: opt, type: 'text' }));
      factsWithType = factsWithType.concat(missingOptions);
      factsBlocks.forEach(fb => {
        var drop = fb.getField("fact");
        const options = factsWithType.filter(f => fb.type.includes(f.type)).map(f => [f.label, f.label]);
        drop.menuGenerator_ = options;
      });
    }
  };

  const handleFactsChange = ({ target: { value } }) => {
    changeMenuOptions(value);
    setFacts(value);
  };

  return (<>
    <Typography variant="h6"
      gutterBottom >
      Blockly Conditions
    </Typography>
    < Autocomplete label="facts"
      placeholder={facts.length ? "" : "Search for facts to be used"}
      suggestions={factOptions}
      initialValue={facts}
      onChange={handleFactsChange}
      margin="normal"
      multiple />
    <div className={classes.container} >
      <Button onClick={generateCode}
        variant="contained"
        color="primary"> Generate
      </Button>
      <Typography color="error" variant="body1"> {error} </Typography> </div> <BlocklyComponent setWorkSpace={setSimpleWorkspace}
        workspace={simpleWorkspace}
        handleChange={handleChange}
        readOnly={false}
        media={'media/'}
        move={
          {
            scrollbars: true,
            drag: true,
            wheel: true
          }
        }
        initialXml={visualConditions || `<xml></xml>`} >
      { /* <Block type="dropdown_number_facts" /> */} <
        Block type="dropdown_text_facts" />
      <Block type="user_cohort" />
      <Block type="math_number" />
      <Block type="text" />
      <Block type="logic_compare" />
      <Block type="logic_operation" />
      <Block type="math_arithmetic" />
      <Block type="math_modulo" />
      <Block type="logic_negate" />
      <Block type="logic_boolean" />
    </BlocklyComponent>
  </>
  );
};

export default BlocklyConditions;;;;;;;;