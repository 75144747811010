import parse from "html-react-parser";

export const highlighter = (searched, content) => {
  if (!content) {
    return content;
  }
  let index = content.toLowerCase().indexOf(searched.toLowerCase());
  let lastIndex = 0;
  let res = '';
  while (index !== -1 && searched !== '') {
    res +=
      `${content.substring(lastIndex, index)}<mark>${content.substring(index, index + searched.length)}</mark>`;
    lastIndex = index + searched.length;
    index = content.toLowerCase().indexOf(searched.toLowerCase(), lastIndex);
  }
  if (res !== '') {
    res += `${content.substring(lastIndex)}`;
    return parse(res);
  } else {
    return content;
  }
}