import React, { useState } from 'react';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ABTestingAndGrouping from 'components/commons/ab-testing-and-grouping/';
import useStyles from './AbTesting.styles';

const AbTestingField = ({ handleChange, form }) => {
  const classes = useStyles();
  const [expandIcon, setExpandIcon] = useState(false);
  const handleExpansionChange = () => {
    setExpandIcon(!expandIcon);
  };

  return (
    <>
      <div className={classes.description}>
        <ExpansionPanel onChange={handleExpansionChange}>
          <ExpansionPanelSummary>
            <div className={classes.summary}>
              <Typography variant="h6" gutterBottom>
                AB Testing
                {!expandIcon ? (
                  <ExpandMoreIcon className={classes.expandMore} />
                ) : (
                  <ExpandLessIcon className={classes.expandMore} />
                )}
              </Typography>

              <Typography variant="body1" gutterBottom>
                AB Testing configuration.
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <ABTestingAndGrouping
              form={form}
              handleChange={handleChange}
              title={false}
              type="program"
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </>
  );
};

export default AbTestingField;
