import makeStyles from '@material-ui/styles/makeStyles';
import { green, red } from '@material-ui/core/colors';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  table: {
    overflow: 'scroll'
  },
  testSuccess: {
    color: green[500]
  },
  testFailure: {
    color: red[500]
  },
  tooltip: {
    backgroundColor: 'transparent'
  },
  tooltipInfoContainer: {
    padding: theme.spacing(1)
  },
  cell: {
    overflow: 'scroll'
  }
}));
