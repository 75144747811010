import React, { useEffect, useRef, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useStyles from './FilesTable.styles';
import Button from 'components/commons/button';
import Select from 'components/commons/select';
import { LANGUAGE_STATUSES, LANGUAGES } from '../../../../data/shared';
import { Grid } from '@material-ui/core';
import { CSVLink } from 'react-csv';

const FilesTable = ({ form, handleFileDownload, handleFileUpload, handleStatusChange }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [csv, setCSV] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);

  useEffect(() => {
    if (!form.enabledLanguages && !form.enabledLanguages) {
      return;
    }
    const enabledLanguages = form.availableLangs?.filter((item) => form.enableLangs.includes(item));
    const disabledLanguages = form.availableLangs?.filter(
      (item) => !form.enableLangs.includes(item)
    );
    setRows([
      ...enabledLanguages.map((item) => ({ name: item, status: '0', loaded: true })),
      ...disabledLanguages.map((item) => ({ name: item, status: '1', loaded: true })),
    ]);

    let languages = Object.keys(LANGUAGES);
    const newLanguageOptions = languages.map((item) => {
      if (form.availableLangs.includes(item)) {
        return { value: item, text: LANGUAGES[item].name, disabled: true };
      }
      return { value: item, text: LANGUAGES[item].name };
    });
    setLanguageOptions([...newLanguageOptions]);
    // eslint-disable-next-line
  }, [form.availableLangs, form.enableLangs]);

  const onFileChange = (e, index) => {
    let file = e.target?.files[0];
    if (file) {
      const language = rows[index].name;
      handleFileUpload(file, language);
    }
  };

  const handleInnerDownload = (index) => {
    const language = rows[index].name;
    handleFileDownload(language).then(blob => {
      setCSV(blob);
      csvLink.current.link.click();
    });
  };

  const uploadFile = (index) => {
    const currentElement = ref.current[index];
    return currentElement.click();
  };

  const addLanguage = () => {
    const filteredRows = [...rows];
    filteredRows.push({});
    setRows([...filteredRows]);
  };

  const handleInnerStatusChange = index => e => {
    const filteredRows = [...rows];
    filteredRows[index].status = e.target.value;
    setRows([...filteredRows]);

    if (e.target.value === '0' && filteredRows[index].name) {
      const newEnabledLanguages = [...form.enableLangs, filteredRows[index].name];
      handleStatusChange(form.availableLangs, newEnabledLanguages);
    } else if (e.target.value === '1' && filteredRows[index].name) {
      const newEnabledLanguages = form.enableLangs.filter(item => item !== filteredRows[index].name);
      handleStatusChange(form.availableLangs, newEnabledLanguages);
    }
  };

  const handleInnerLanguageNameChange = index => e => {
    const filteredRows = [...rows];
    filteredRows[index].name = e.target.value;
    setRows([...filteredRows]);

    const optionIndex = languageOptions.findIndex(item => item.value === e.target.value);
    const newLanguageOptions = [...languageOptions];
    newLanguageOptions[optionIndex].disabled = true;
    setLanguageOptions([...newLanguageOptions]);

    let newAvailableLanguages = [...form.availableLangs];
    if (!form.availableLangs.includes(e.target.value)) {
      newAvailableLanguages.push(filteredRows[index].name);
    }
    const newEnabledLanguages = [...form.enableLangs];
    if (filteredRows[index].status === '0') {
      newEnabledLanguages.push(filteredRows[index].name);
    }
    handleStatusChange(newAvailableLanguages, newEnabledLanguages);
  };

  const ref = useRef({});
  const csvLink = useRef({});
  return (
    <>
      <CSVLink
        data={csv}
        filename="data.csv"
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell>Available Languages</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={`${row.name}-${index}`}>
                <TableCell component="th" scope="row">
                  <Select
                    label="Name"
                    id="lname"
                    onChange={handleInnerLanguageNameChange(index)}
                    value={row.name}
                    options={languageOptions}
                    fullWidth
                    required
                    disabled={!!row.loaded}
                  />
                </TableCell>
                <TableCell align="right">
                  <Select
                    label="Status"
                    id="status"
                    onChange={handleInnerStatusChange(index)}
                    value={row.status}
                    options={LANGUAGE_STATUSES}
                    fullWidth
                    required
                  />
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => handleInnerDownload(index)} color="secondary" variant="outlined">
                    Download
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <input
                    type="file"
                    max="1"
                    ref={element => (ref.current[index] = element)}
                    style={{
                      visibility: 'hidden',
                      position: 'absolute',
                    }}
                    accept=".csv"
                    name="file"
                    onChange={(e) => onFileChange(e, index)}
                  />
                  <Button onClick={() => uploadFile(index)} color="primary" variant="outlined" disabled={!row.name}>
                    Upload
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container>
        <Button onClick={addLanguage} className={classes.button} color="primary" variant="contained" type="submit" size="medium">
          Add Language
        </Button>
      </Grid>
    </>
  );
};

export default FilesTable;