import { authorizedAxios } from 'config/axios';
import InsightFactory from 'factories/insights';

const FACTUARY_URL = '/factuary/api';
/**
 * Service that will request authors of insights from the factuary api
 */
export const fetchAuthors = () => {
  return authorizedAxios.get(`${FACTUARY_URL}/v1/insights/authors`);
};

/**
 * Service that will request a single insight from the factuary api
 *
 * @param {number} id - the id of the insight you wish to load
 * @return {Promise}
 */
export const fetchInsight = id => {
  return authorizedAxios.get(`${FACTUARY_URL}/v1/insights/${id}`).then(response => ({
    ...response,
    data: InsightFactory.toModel({
      ...response.data
    })
  }));
};

/**
 * Service that will get the status of insight tests from the factuary api
 *
 * @param {number} id - the id of the insight you wish to load
 * @return {Promise}
 */
export const executeTestsOnInsight = id => {
  return authorizedAxios.get(`${FACTUARY_URL}/v1/insights/${id}/test`);
};

/**
 * Service that will request insights from the factuary api.
 * @param {array} labels - filter insights with by list of label ids
 * @param {null} purpose - not sure what this used for, here for parity
 * @param {number} author - filter insights with the id of an author
 * @param {string} query - filter fields by an arbitrary string
 * @param {string} status - filter insights by publication status
 * @param {boolean} showAll - return all results or a limited set
 * @return {Promise}
 *
 * @todo Keep an eye on the ability to abort fetch if people start making
 *       machine gun requests.
 * @see https://github.com/whatwg/fetch/issues/27
 */
export const fetchInsights = (filters, page) => {
  const params = new URLSearchParams();

  const copyFilters = { ...filters };

  if (copyFilters['searched text']?.length) {
    params.append('title', copyFilters['searched text'].join('|'))
    params.append('body', copyFilters['searched text'].join('|'))
    params.append('body_html', copyFilters['searched text'].join('|'))
    delete copyFilters['searched text']
  }

  if (copyFilters) {
    Object.entries(copyFilters).forEach(([type, values]) => {
      if (values.length) params.append(type, values.join('|'));
    });
  }

  if (page) params.append('page', page);
  params.append('per_page', 10);

  return authorizedAxios.get(`${FACTUARY_URL}/v2/content/filter?content_type=insight&${params}`).then(response => {
    return ({
      meta: {
        nextPage: response.meta.next_page,
        currentPage: response.meta.page
      },
      data: {
        insights: response.data.map(InsightFactory.toModel)
      }
    })
  })
    .catch(err => console.error(err));
};

export const getLanguageFile = (insightId, languageCode) => {
  return authorizedAxios.get(`${FACTUARY_URL}/v2/strings/insight/csv?content_id=${insightId}&lang=${languageCode}`)
    .then((response) => response)
    .catch(err => '');
};

export const getLanguageFileTemplate = (insightId) => {
  return authorizedAxios.get(`${FACTUARY_URL}/v2/strings/insight/csv?content_id=${insightId}`)
    .then((response) => response)
    .catch(err => '');
};

export const postLanguageFile = (languageFile, insightId, languageCode) => {
  return authorizedAxios.post(`${FACTUARY_URL}/v2/strings/insight/csv?content_id=${insightId}&lang=${languageCode}`, languageFile);
};

/**
 * Persist an existing insight to update it's data.
 *
 * @param {object} insight - the new insight data.
 * @param {number} insight.id - will be used to identify the insight
 * @return {Promise}
 */
export const updateInsight = insight => {
  return authorizedAxios.put(`${FACTUARY_URL}/v1/insights/${insight.id}`, InsightFactory.toDTO(insight));
};

/**
 * Persist a new insight.
 *
 * @param {object} insight - the new insight data.
 * @return {Promise}
 */
export const createInsight = insight => {
  return authorizedAxios.post(`${FACTUARY_URL}/v1/insights`, InsightFactory.toDTO(insight));
};
