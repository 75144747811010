import React, { useState, useEffect } from 'react';
import { useDebounce } from 'utils/hooks';
import { cloneDeep } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { Box, Button, Grid, IconButton, TextField, Typography, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Checkbox from 'components/commons/checkbox';
import validateTest from '../blockly-conditions/utils/validate-test';

import useStyles from './ItemTestsField.styles';

const initialState = {
  conditions: []
};

const ItemTestsField = ({ handleChange, value, title = 'Tests', tipMessage, setValidating = () => { }, codeToValidate = [] }) => {
  const classes = useStyles();
  const [tests, setTests] = useState(value || initialState);
  const [testError, setTestError] = useState(false);
  // eslint-disable-next-line
  const debouncedTests = useDebounce(tests, 500);

  useEffect(() => {
    if (debouncedTests) {
      handleExternalChange();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTests]);

  useEffect(() => {
    if (value) {
      setTests(value);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setTestError(false);
    if (tests) {
      tests.conditions.forEach(test => {
        if (test.expected === undefined) {
          return;
        }
        const result = codeToValidate.some(code => {
          return !validateTest(code, test.facts, test.expected);
        });
        if (result) {
          setTestError(true);
        }
      });
    }
    setValidating(false);
  }, [codeToValidate]);// eslint-disable-line react-hooks/exhaustive-deps

  const handleAddCondition = () => {
    const newCondition = {
      expected: false,
      facts: [['', '']],
    };
    const oldTestsConditions = tests?.conditions;
    setTests({
      ...tests,
      conditions:
        oldTestsConditions != null ? [...oldTestsConditions, newCondition] : [newCondition],
    });
  };

  const handleDeleteCondition = (conditionKey) => {
    setTests({
      ...tests,
      conditions: tests.conditions.filter((item, index) => index !== conditionKey),
    });
  };

  const handleAddFact = (conditionKey) => {
    const newTests = cloneDeep(tests);
    const conditionAtIndex = newTests.conditions[conditionKey];
    newTests.conditions[conditionKey] = {
      ...conditionAtIndex,
      facts: [...conditionAtIndex.facts, ['', '']],
    };
    setTests(newTests);
  };

  const handleDeleteFact = (conditionKey, factKey) => {
    const newTests = cloneDeep(tests);
    const conditionAtIndex = newTests.conditions[conditionKey];
    newTests.conditions[conditionKey] = {
      ...conditionAtIndex,
      facts: conditionAtIndex.facts.filter((item, index) => index !== factKey),
    };
    setTests(newTests);
  };

  const handleCheckChange =
    (conditionKey) =>
      ({ target: { checked } }) => {
        const newTests = cloneDeep(tests);
        const conditionAtIndex = newTests.conditions[conditionKey];
        newTests.conditions[conditionKey] = { ...conditionAtIndex, expected: checked };
        setTests(newTests);
        // Validate code
        setValidating(true);
      };

  const handleFactNameChange =
    (conditionKey, factKey) =>
      ({ target: { value } }) => {
        const newTests = cloneDeep(tests);
        newTests.conditions[conditionKey]['facts'][factKey][0] = value;
        setTests(newTests);
        // Validate code
        setValidating(true);
      };

  const handleFactValueChange =
    (conditionKey, factKey) =>
      ({ target: { value } }) => {
        const newTests = cloneDeep(tests);
        newTests.conditions[conditionKey]['facts'][factKey][1] = value;
        setTests(newTests);
        // Validate code
        setValidating(true);
      };

  const handleExternalChange = () => {
    const newState = {
      ...debouncedTests
    };
    handleChange('tests')({ target: { value: newState } });
  };

  const renderCondition = (condition, conditionKey) => {
    const isFactsAnArray = Array.isArray(condition.facts);
    if (!isFactsAnArray) {
      condition.facts = Object.entries(condition.facts);
    }
    return (
      <Box key={conditionKey} mt={1} mb={1}>
        {condition?.facts.map(([fact, value], factKey) => (
          <Grid key={factKey} container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
              <TextField
                id={`condition-fact-${conditionKey}`}
                type="text"
                value={fact}
                onChange={handleFactNameChange(conditionKey, factKey)}
                margin="normal"
                label="Fact"
                fullWidth
                InputProps={{
                  classes: {
                    root: classes.inputFont,
                  },
                }}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id={`condition-fact-value-${conditionKey}`}
                type="text"
                value={value}
                onChange={handleFactValueChange(conditionKey, factKey)}
                margin="normal"
                label="Value"
                fullWidth
                error={testError}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <IconButton onClick={() => handleDeleteFact(conditionKey, factKey)} size="small">
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        {condition.expected != null && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Checkbox
                checked={condition.expected}
                label="Test should be true"
                onChange={handleCheckChange(conditionKey)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button onClick={() => handleAddFact(conditionKey)}>
                <AddIcon />
                Add fact
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button onClick={() => handleDeleteCondition(conditionKey)}>
                <ClearIcon />
                Delete condition
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {title}
        {tipMessage ? (
          <Tooltip title={tipMessage} placement="bottom-start">
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Typography>
      {testError && <small className={classes.inputError}>Error: Please review your facts values and make sure you include all.</small>}
      {tests.conditions.map(renderCondition)}
      <Button onClick={() => handleAddCondition()} color="secondary">
        Add Condition Test
      </Button>
    </div>
  );
};

export default ItemTestsField;
