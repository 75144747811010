import {
  TYPE_REQUEST_PROGRAM,
  TYPE_RECEIVE_PROGRAM,
  TYPE_RECEIVE_PROGRAM_ERROR,
  TYPE_UPDATE_PROGRAM,
  TYPE_UPDATE_PROGRAM_SUCCESS,
  TYPE_UPDATE_PROGRAM_ERROR,
  TYPE_CREATE_PROGRAM,
  TYPE_CREATE_PROGRAM_SUCCESS,
  TYPE_CREATE_PROGRAM_ERROR,
  TYPE_RESET_PROGRAM,
  TYPE_RECEIVE_CONTENT,
} from 'data/programs';

export const initialState = {
  program: {},
  isFetching: false,
  lastUpdated: null,
  testResult: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_PROGRAM:
    case TYPE_CREATE_PROGRAM:
      return {
        ...state,
        isFetching: true,
        testResult: true,
      };
    case TYPE_UPDATE_PROGRAM:
    case TYPE_CREATE_PROGRAM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        program: action.program,
        testResult: true,
      };
    case TYPE_UPDATE_PROGRAM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        program: action.program,
        testResult: true,
        tenantsConflicts: action.tenants_conflicts
      };
    case TYPE_RECEIVE_PROGRAM:
      return {
        ...state,
        isFetching: false,
        program: action.program,
        testPassed: action.testPassed,
        testResults: action.testResults,
        testResult: true,
      };
    case TYPE_RECEIVE_CONTENT:
      return {
        ...state,
        isFetching: false,
        modules: action.modules,
        testResult: true,
      };
    case TYPE_RECEIVE_PROGRAM_ERROR:
    case TYPE_UPDATE_PROGRAM_ERROR:
    case TYPE_CREATE_PROGRAM_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        testResult: false,
      };
    case TYPE_RESET_PROGRAM:
      return { ...initialState };
    default:
      return state;
  }
};
