import {
  TYPE_REQUEST_MODULES,
  TYPE_RECEIVE_MODULES,
  TYPE_RECEIVE_MODULES_CACHED,
  TYPE_RECEIVE_MODULES_ERROR
} from 'data/modules';

export const initialState = {
  modules: [],
  isFetching: false,
  lastUpdated: null,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_MODULES:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case TYPE_RECEIVE_MODULES:
    case TYPE_RECEIVE_MODULES_CACHED:
      return {
        ...state,
        modules: action.modules,
        lastUpdated: action.lastUpdated,
        isFetching: action.isFetching
      };
    case TYPE_RECEIVE_MODULES_ERROR:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error
      };
    default:
      return state;
  }
};
