import React, { useState, useEffect } from 'react';
import { isEmpty, update, constant } from 'lodash';
import { Typography, CircularProgress } from '@material-ui/core';

import TestResults from 'components/commons/test-results';
import InsightForm from 'components/views/insights/form';
import useStyles from './EditInsight.styles';
import { tenantHandler } from 'services/shared/handlers';

const EditInsight = ({ actions, facts, insight, insightId, labels, tenants }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});
  const { testPassed, testResults } = insight;

  useEffect(() => {
    actions.onMount();
    actions.fetchInsight(insightId);
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(insight.insight)) {
      setForm({
        ...insight.insight
      });
    };
  }, [insight]);

  const handleChange = name => e => {
    setForm({ ...update(form, name, constant(e.target.value)) });
  };

  const updatedLabels = () => {
    const newLabels = form.labels.map(index => {
      const { name, flags, id, type } = labels.labels[index];
      return { name, flags, id, type };
    });
    return newLabels;
  };

  const handleCheck = name => e => setForm({ ...update(form, name, constant(e.target.checked)) });

  const handleTenantCheck = (tenantIndex) => ({ target }) => {
    tenantHandler(tenantIndex, target, form, tenants, setForm);
  };

  const handleSubmit = () => {
    if (validateFields())
      actions.updateInsight({ ...form, labels: updatedLabels() });
  };

  const validateFields = () => {
    return !(form.priority > 200);
  };

  const handleFileDownload = (language) => {
    return actions.getLanguageFile(form.id, language);
  };

  const handleTemplateFileDownload = () => {
    return actions.getLanguageFileTemplate(form.id);
  };

  const handleFileUpload = (file, language) => {
    let languageFile = new FormData();
    languageFile.append('file', file, file.name);
    actions.saveLanguageFile(languageFile, form.id, language);
  };

  const handleStatusChange = (availableLanguages, disabledLanguages) => {
    setForm({
      ...form,
      availableLangs: [...availableLanguages],
      enableLangs: [...disabledLanguages]
    });
  };

  const renderContent = () => {
    if (isEmpty(form) || insight.isFetching) {
      return (<div className={classes.loadingContainer}>
        <CircularProgress />
      </div>);
    }

    if (insight.insight.type !== "0" && insight.insight.type !== "1") {
      return (
        <h2>Content not available</h2>
      );
    }

    return (
      <InsightForm
        facts={facts}
        tenants={tenants}
        form={form}
        setForm={setForm}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleTenantCheck={handleTenantCheck}
        handleFileDownload={handleFileDownload}
        handleTemplateFileDownload={handleTemplateFileDownload}
        handleFileUpload={handleFileUpload}
        handleStatusChange={handleStatusChange}
        labels={labels}
        onSubmit={handleSubmit}
        nonEditableName={true}
        testResults={testResults}
      />
    );
  };

  return (
    <div className={classes.pageContainer}>
      <div className={classes.headerContainer}>
        <Typography variant="h5" display="inline">
          Edit Insight
        </Typography>
        <div className={classes.testContainer}>
          <TestResults testPassed={testPassed} testResults={testResults} />
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

export default EditInsight;
