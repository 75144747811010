import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import fetchTenantsIfNeeded from 'actions/tenants';
import fetchModules from 'actions/modules';
import { createProgram, resetProgram } from 'actions/program';
import { CreateProgram } from 'views';
import { fetchFactsIfNeeded } from 'actions/facts';

const mapStateToProps = ({ modules, tenants, facts }) => ({ modules, tenants, facts });

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        createProgram
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchModules());
      dispatch(fetchTenantsIfNeeded());
      dispatch(fetchFactsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetProgram());
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProgram);
