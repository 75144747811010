import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Typography, CircularProgress } from '@material-ui/core';

import TestResults from 'components/commons/test-results';
import ModuleForm from 'components/views/programs/modules/form';
import useStyles from './EditModule.styles';
import { ADDITIONAL_CONTENT_CONDITIONS } from 'data/modules';

const EditModule = ({ actions, programs, programModule, programModuleId, content, facts }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});
  const [contentForm, setContentForm] = useState({
    query: { name: '', index: null },
    options: [],
    programs: [],
    type: [],
    conditions: {},
    tests: {},
  });

  useEffect(() => {
    actions.onMount();
    actions.fetchModule(programModuleId);
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(programModule.module)) {
      const newModule = { ...programModule.module };
      if (newModule.steps) {
        const newSteps = newModule?.steps?.map((item, index) => {
          const edge = newModule.edges[index] != null ? newModule.edges[index] : {};
          return {
            ...item,
            trigger: edge.trigger,
            tests: edge.tests,
            edgeConditions: edge.conditions,
            contentSubStatus: edge.contentSubStatus ? edge.contentSubStatus : ADDITIONAL_CONTENT_CONDITIONS[item.contentType].map(() => ""),
            visualConditions: edge.visualConditions,
            delay: edge.delay
          };
        });
        setForm({ ...newModule, steps: newSteps });
      } else {
        setForm(newModule);
      }
      setContentForm({
        ...contentForm,
        programs: programs.programs.map((program) => {
          return { value: program.id, text: program.name };
        }),
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programModule.module]);

  const handleSubmit = (cancel = false, back = false) => {
    const newEdges = form.steps.map((item, index) => {
      if (index + 1 >= form.steps.length) {
        return {};
      }
      const nextItem = form.steps[index + 1];
      return {
        delay: item.delay,
        src: item.contentIdentifier,
        dst: nextItem.contentIdentifier,
        conditions: item.edgeConditions,
        trigger: item.trigger,
        tests: item.tests,
        contentSubStatus: item.contentSubStatus,
        visualConditions: item.visualConditions
      };
    });
    newEdges.pop();
    if (form.steps.length === 1) {
      newEdges.unshift({
        dst: form.steps[0].contentIdentifier,
        conditions: [],
      });
    }

    actions.updateModule(
      { ...form, edges: newEdges },
      cancel,
      back
    );
  };

  const handlers = {
    handleSubmit,
  };

  const isFetching = programs.isFetching || programModule.isFetching;

  return (
    <div className={classes.pageContainer}>
      <div className={classes.headerContainer}>
        <Typography variant="h5">Edit Module</Typography>
        <div className={classes.testContainer}>
          <TestResults testPassed={programModule.testResult} />
        </div>
      </div>
      {isEmpty(form) || isFetching ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <ModuleForm
          {...{
            form,
            contentForm,
            handlers,
            setForm,
            setContentForm,
            edit: true,
            actions,
            content,
            facts
          }}
        />
      )}
    </div>
  );
};

export default EditModule;
