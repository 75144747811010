import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Typography, CircularProgress } from '@material-ui/core';

import ModuleForm from 'components/views/programs/modules/form';
import useStyles from './CreateModule.styles';

const CreateModule = ({ actions, program, programId, content, facts }) => {
  const classes = useStyles();
  const [form, setForm] = useState();
  const [contentForm, setContentForm] = useState({
    query: { name: '', index: null },
    options: [],
    type: [],
  });

  useEffect(() => {
    actions.fetchProgram(programId);
    actions.onMount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(program.program))
      setForm({
        name: '',
        programs: [program.program],
        steps: [{}],
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program.program]);

  const handleSubmit = (cancel, back) => {
    const newEdges = form.steps.map((item, index) => {
      if (index + 1 >= form.steps.length) {
        return {};
      }
      const nextItem = form.steps[index + 1];
      return {
        delay: item.delay,
        src: item.contentIdentifier,
        dst: nextItem.contentIdentifier,
        conditions: item.edgeConditions,
        trigger: item.trigger,
        tests: item.tests,
        contentSubStatus: item.contentSubStatus,
        visualConditions: item.visualConditions
      };
    });
    newEdges.pop();
    actions.createModule({ ...form, edges: newEdges }, cancel, back);
  };

  const handlers = {
    handleSubmit,
  };

  const isFetching = program.isFetching;

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create Module</Typography>
      {isFetching ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <ModuleForm
          {...{
            form,
            contentForm,
            handlers,
            setForm,
            setContentForm,
            edit: false,
            actions,
            content,
            facts
          }}
        />
      )}
    </div>
  );
};

export default CreateModule;
