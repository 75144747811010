import TestFactory from './tests';
import { cleanConditions } from "./utils";

export default class PledgeFactory {
  static toDTO(model) {
    return {
      category: parseInt(model.category),
      priority: parseInt(model.priority),
      cooldown: parseInt(model.cooldown),
      ttl: parseInt(model.ttl),
      type: parseInt(model.type),
      content_key: model.name,
      status: parseInt(model.status),
      goal: model.goal,
      labels: model.labels,
      ab_test_names: model.abTestNames,
      ab_test_name: model.abTestName,
      ab_test_side: model.abTestSide,
      group_identifier: model.groupIdentifier,
      group_name: model.groupName,
      group_name_cooldown: model.groupNameCooldown,
      end_time: model.endTime.toString(),
      start_time: model.startTime.toString(),
      available_langs: model.availableLangs,
      enable_langs: model.enableLangs,
      lang: model.lang,
      app_id: parseInt(model.appId),
      multi_day: model.multiDay,
      is_ladder: model.isLadder,
      rhs_params: model.rhsParams,
      extra_params: model.extraParams,
      rhs_logic: model.rhsLogic,
      conditions: cleanConditions(model.conditions),
      tests: TestFactory.testToDTO(model.tests),
      author_uid: parseInt(model.authorId),
      header: model.headerText,
      body: model.bodyText,
      decline: model.declineText,
      accept: model.acceptText,
      tenants: model.tenants,
      exclude_tenants: model.excludeTenants,
      visual_conditions: model.visualConditions
    };
  }

  static toModel(dto) {
    return {
      category: dto.category?.toString(),
      priority: dto.priority?.toString(),
      cooldown: dto.cooldown?.toString() ?? '',
      ttl: dto.ttl?.toString() ?? '',
      type: dto.type?.toString(),
      name: dto.content_key || dto.name,
      status: dto.status?.toString(),
      goal: dto.goal,
      body: dto.body,
      labels: dto.labels,
      abTestNames: dto.ab_test_names,
      abTestName: dto.ab_test_name ?? '',
      abTestSide: dto.ab_test_side ?? '',
      groupIdentifier: dto.group_identifier ?? '',
      groupName: dto.group_name ?? '',
      groupNameCooldown: dto.group_name_cooldown ?? '',
      endTime: dto.end_time,
      startTime: dto.start_time,
      availableLangs: dto.available_langs,
      enableLangs: dto.enable_langs,
      lang: dto.lang ?? '',
      appId: (dto.app_id?.toString()) ?? '',
      multiDay: !!dto.multi_day,
      isLadder: !!dto.is_ladder,
      rhsParams: dto.rhs_params,
      extraParams: dto.extra_params,
      rhsLogic: dto.rhs_logic,
      conditions: dto.conditions,
      tests: TestFactory.testToModel(dto.tests),
      authorId: dto.author_uid?.toString(),
      headerText: dto.header,
      bodyText: dto.body,
      declineText: dto.decline,
      acceptText: dto.accept,
      tenants: dto.tenants,
      excludeTenants: dto.exclude_tenants,
      id: dto.id,
      visualConditions: dto.visual_conditions
    };
  }
}
