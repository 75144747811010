import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { fetchPledges } from 'actions/pledges';

import { Pledges } from 'views';

const mapStateToProps = state => ({
  pledges: state.pledges,
  labels: state.labels
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        fetchPledges: fetchPledges,
        push
      },
      dispatch
    )
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Pledges);
