import React, { useRef, useState } from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import FilesTable from '../../shared/files-table';
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

const useStyles = makeStyles({
  filesTable: {
    margin: '0 auto',
    width: '50%'
  },
  button: {
    margin: '1rem auto',
  }
});

const TranslationsTab = ({ form, handleChange, handleFileDownload, handleTemplateFileDownload, handleStatusChange, handleFileUpload }) => {
  const classes = useStyles();
  const [csv, setCSV] = useState([]);

  const handleInnerTemplateDownload = () => {
    handleTemplateFileDownload().then(blob => {
      setCSV(blob);
      csvLink.current.link.click();
    });
  };

  const csvLink = useRef({});

  return (
    <>
      <CSVLink
        data={csv}
        filename="data.csv"
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
      <Typography
        variant="body2"
        gutterBottom>
        Download a &nbsp;
        <Link component="button" variant="body2" onClick={() => handleInnerTemplateDownload()}>Template</Link>
      </Typography>
      <Grid container className={classes.filesTable}>
        <FilesTable
          form={form}
          handleChange={handleChange}
          handleFileDownload={handleFileDownload}
          handleStatusChange={handleStatusChange}
          handleFileUpload={handleFileUpload}>
        </FilesTable>
      </Grid>
    </>
  );
};

TranslationsTab.propTypes = {
  form: PropTypes.object,
  handleChange: PropTypes.func,
  handleFileDownload: PropTypes.func,
  handleTemplateFileDownload: PropTypes.func,
  handleStatusChange: PropTypes.func,
  handleFileUpload: PropTypes.func
};

export default TranslationsTab;
