export default class TestFactory {
  static testToDTO(testModel) {
    return {
      ...testModel,
      conditions:
        testModel?.conditions?.map((item) => ({ ...item, facts: TestFactory.factsToDTO(item.facts) }))
          .filter((item) => item.expected != null) || [],
    };
  }


  static factsToDTO(factsModel) {
    const newFacts = Object.fromEntries(factsModel);
    if (newFacts['user_cohort'] && typeof newFacts['user_cohort'] == "string") {
      newFacts['user_cohort'] = newFacts['user_cohort'].split(",");
    }
    return newFacts;
  }

  static testToModel(testDTO) {
    return {
      ...testDTO,
      conditions:
        testDTO?.conditions?.map((item) => ({
          ...item,
          facts: item.facts != null ? Object.entries(item.facts) : [],
        })) || [],
    };
  }
}