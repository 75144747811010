import { formatString } from 'utils/reports';

export default class ReportFactory {
  static toModel(dto, availableTenants) {
    return {
      id: dto.id,
      status: dto.status,
      type: dto.type,
      priority: dto.priority,
      ttl: dto.ttl,
      cooldown: dto.cooldown,
      contentType: dto.content_type,
      contentIdentifier: dto.content_identifier,
      name: dto.name,
      introHeader: dto.content_type === "insight" || dto.content_type === "pledge" ? dto.title : dto.intro_header,
      introBody: formatString(dto.content_type === "insight" || dto.content_type === "pledge" ? dto.body : dto.intro_body),
      conditions: dto.conditions.join(", "),
      tenants: ReportFactory.tenantsToModel(dto.exclude_tenants, availableTenants)
    };
  }

  static tenantsToModel(exclude_tenants, availableTenants) {
    const tenantIds = availableTenants.map(item => item.id);
    let difference = tenantIds.filter(x => !exclude_tenants.includes(x));
    const tenantNames = availableTenants.filter(item => difference.includes(item.id)).map(item => item.name);
    return tenantNames.join(", ");
  }
}