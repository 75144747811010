import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  itemContainer: {
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0'
  },
  autocompleteContainer: {
    position: 'relative',
    width: '100%'
  },
  inputSuggestions: {
    position: 'absolute',
    top: '2rem',
    backgroundColor: 'white',
    zIndex: '20',
    width: '100%',
  },
  inputSuggestionsItem: {
    height: '2rem',
    padding: '0.5rem'
  },
  inputFieldContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  inputBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  inputError: {
    color: 'red',
    paddingLeft: '0.5rem'
  },
  inputInfo: {
    color: 'gray',
    paddingLeft: '0.5rem'
  },
  inputField: {
    lineHeight: '1.8rem',
    padding: '0 0.5rem',
    borderRadius: '5px',
    border: '1px solid gray',
    flex: '1'
  },
  actions: {
    display: 'flex',
    gap: '1rem'
  }
});
