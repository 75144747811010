import * as service from 'services/surveys';
import * as data from 'data/surveys';

const requestSurveys = () => ({
  type: data.TYPE_REQUEST_SURVEYS,
  isFetching: true,
});

const receiveSurveys = (json) => ({
  type: data.TYPE_RECEIVE_SURVEYS,
  isFetching: false,
  surveys: json.data.surveys,
  currentPage: json.meta.currentPage,
  nextPage: json.meta.nextPage
});

const receiveSurveysError = (error) => ({
  type: data.TYPE_RECEIVE_SURVEYS_ERROR,
  isFetching: false,
  error: error.message ? error.message : error,
});

export const fetchSurveys = (filters = null, page = 1) => (dispatch) => {
  dispatch(requestSurveys());
  return service
    .fetchSurveys(filters, page)
    .then((surveys) => dispatch(receiveSurveys(surveys)))
    .catch((error) => dispatch(receiveSurveysError(error)));
};
