export const TYPE_REQUEST_PROGRAMS = 'programs/REQUEST_PROGRAMS';
export const TYPE_RECEIVE_PROGRAMS = 'programs/RECEIVE_PROGRAMS';
export const TYPE_RECEIVE_PROGRAMS_CACHED = 'programs/RECEIVE_PROGRAMS-CACHED';
export const TYPE_RECEIVE_PROGRAMS_ERROR = 'programs/RECEIVE_PROGRAMS_ERROR';

export const TYPE_REQUEST_PROGRAM = 'program/REQUEST_PROGRAM';
export const TYPE_RECEIVE_PROGRAM = 'program/RECEIVE_PROGRAM';
export const TYPE_RECEIVE_PROGRAM_ERROR = 'program/RECEIVE_PROGRAM_ERROR';
export const TYPE_UPDATE_PROGRAM = 'program/UPDATE_PROGRAM';
export const TYPE_UPDATE_PROGRAM_SUCCESS = 'program/UPDATE_PROGRAM_SUCCESS';
export const TYPE_UPDATE_PROGRAM_ERROR = 'program/UPDATE_PROGRAM_ERROR';
export const TYPE_CREATE_PROGRAM = 'program/CREATE_PROGRAM';
export const TYPE_CREATE_PROGRAM_SUCCESS = 'program/CREATE_PROGRAM_SUCCESS';
export const TYPE_CREATE_PROGRAM_ERROR = 'program/CREATE_PROGRAM_ERROR';
export const TYPE_RESET_PROGRAM = 'program/RESET_PROGRAM';
export const TYPE_REQUEST_CONTENT = 'program/REQUEST_CONTENT';
export const TYPE_RECEIVE_CONTENT = 'program/RECEIVE_CONTENT';

export const PROGRAM_STATE = [
  { value: 'draft', text: 'Draft (Default)' },
  { value: 'live', text: 'Live' },
  { value: 'deprecated', text: 'Deprecated' },
];

export const PROGRAM_MESSAGE =
  'This field contains the internal-facing name that will be used to identify the program in Aristotle.';

export const MODULE_MESSAGE =
  'This field contains the internal-facing name that will be used to identify each module of a program in Aristotle.';

export const MODULE_CONDITIONS_MESSAGE =
  'These are the conditions for transitioning from this module to the next. If left blank, the next module will be triggered without any transition conditions needing to be met.';

export const MODULE_TESTS_MESSAGE =
  'These are the tests for the Module Transition Conditions. These tests must be filled out if Module Transition Conditions are present.';

export const ADD_ITEM_MESSAGE = 'Add an existing module to this program.';

export const ADD_NEW_ITEM_MESSAGE = 'Create and add a new module to this program';
