import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';

const ABTestingAndGrouping = ({ form, handleChange, type, title = true }) => {
  return (
    <>
      {title ? <Typography variant="h6" gutterBottom>
        A/B Testing
      </Typography> : ''}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="abTestName"
            label="A/B Test name"
            type="text"
            value={form.abTestName}
            onChange={handleChange('abTestName')}
            margin="normal"
            helperText="Important: This field must match a related config."
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{
              maxLength: 1,
            }}
            id="abTestSide"
            label="Test side"
            type="text"
            value={form.abTestSide}
            onChange={handleChange('abTestSide')}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
        Grouping
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="groupIdentifier"
            label={`Group identifier to link ${type}s`}
            type="text"
            value={form.groupIdentifier}
            onChange={handleChange('groupIdentifier')}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <Typography variant="body2" gutterBottom>
            {` Use if you want it to swap out with another ${type} on the same day. This field requires
            the input to consist of only letters, numbers, and underscores.`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="groupName"
            label="Group Name"
            type="text"
            value={form.groupName}
            onChange={handleChange('groupName')}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="groupNameCooldown"
            label={`Group name days`}
            type="number"
            value={form.groupNameCooldown}
            onChange={handleChange('groupNameCooldown')}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" gutterBottom>
            {`Use if this is part of a group where you want the user to only receive a max of 1
            ${type} from the group every "Group name days".`}
          </Typography>
        </Grid>
      </Grid>

    </>
  );
};

export default ABTestingAndGrouping;
