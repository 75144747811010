import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  pageContainer: {
    height: '80%',
    width: '85%',
    overflow: 'auto',
  },
  loadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  },
  paperStyles : {
    padding : theme.spacing(2)
  }
}));
