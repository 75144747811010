import * as data from 'data/insights';

/**
 * @ignore
 */
export const initState = {
  insight: {},
  isFetching: false,
  lastUpdated: null
};

export default (state = initState, action) => {
  switch (action.type) {
    case data.TYPE_REQUEST_INSIGHT:
      return {
        ...state,
        isFetching: true,
        lastUpdated: null,
        error: null
      };

    case data.TYPE_RECEIVE_INSIGHT:
      return {
        ...state,
        isFetching: false,
        error: null,
        lastUpdated: action.lastUpdated,
        insight: action.insight,
        testPassed: action.testPassed,
        testResults: action.testResults
      };

    case data.TYPE_RECEIVE_INSIGHT_ERROR:
      return {
        ...state,
        isFetching: false,
        lastUpdated: null,
        error: action.error
      };

    case data.TYPE_UPDATE_INSIGHT:
      return {
        ...state,
        insight: action.insight,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        error: null
      };

    case data.TYPE_UPDATE_INSIGHT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        insight: action.insight,
        testPassed: action.testPassed,
        testResults: action.testResults,
        tenantsConflicts: action.tenants_conflicts
      };

    case data.TYPE_UPDATE_INSIGHT_ERROR:
      // @todo if srv didn't respond with json there is no
      //       update time, so we're not going to track it.
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case data.TYPE_CREATE_INSIGHT:
      return {
        ...state,
        isFetching: false,
        insight: action.insight,
        error: null
      };

    case data.TYPE_CREATE_INSIGHT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        insight: action.insight,
        error: null
      };

    case data.TYPE_REQUEST_INSIGHT_DEFAULTS:
      return {
        ...state,
        isFetching: true,
        lastUpdated: null,
        error: null
      };

    case data.TYPE_RECEIVE_INSIGHT_DEFAULTS:
    case data.TYPE_RECEIVE_INSIGHT_DEFAULTS_CACHED:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        insight: Object.assign({}, action.insight, state.insight),
        error: null
      };

    case data.TYPE_RECEIVE_INSIGHT_DEFAULTS_ERROR:
      return {
        ...state,
        isFetching: false,
        lastUpdated: null,
        error: action.error
      };

    case data.TYPE_CREATE_INSIGHT_ERROR:
      return {
        ...state,
        isFetching: false,
        lastUpdated: null,
        error: action.error
      };

    case data.TYPE_REQUEST_INSIGHT_TEST:
      return {
        ...state,
        lastUpdated: null,
        error: null
      };

    case data.TYPE_RECEIVE_INSIGHT_TEST:
      return {
        ...state,
        insight: { ...state.insight, tested: true },
        lastUpdated: action.lastUpdated,
        error: null
      };

    case data.TYPE_RECEIVE_INSIGHT_TEST_ERROR:
      return {
        ...state,
        insight: { ...state.insight, tested: false },
        error: action.error
      };

    case data.TYPE_COPY_INSIGHT:
      return {
        ...state,
        insight: {
          ...action.insight,
          id: null,
          name: `Copy of ${action.insight.name}`,
          status: 'draft'
        },
        error: null,
        lastUpdated: null,
        isFetching: false
      };

    case data.TYPE_RESET_INSIGHT:
      return { ...initState };

    default:
      return state;
  }
};
