import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';

import useStyles from './FilterMenu.styles';

const FilterMenu = ({ anchorEl, onClose, onSelected, id, open, menuOptions }) => {
  const classes = useStyles();
  const [sectionAnchorEl, setSectionAnchorEl] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  const handleSelectSection = value => e => {
    setSelectedSection(value);
    setSectionAnchorEl(e.currentTarget);
  };

  const handleSelectOption = (type, value) => () => {
    onSelected({ type, value });
    onClose();
    setSectionAnchorEl(null);
  };

  const [selectedOption] = selectedSection
    ? menuOptions.filter(option => option.value === selectedSection)
    : [];

  return (
    <>
      <Menu id={id} anchorEl={anchorEl} open={open} onClose={onClose} elevation={1}>
        {menuOptions.map(option => {
          return (
            <MenuItem
              key={option.value}
              className={classes.menuOption}
              onClick={handleSelectSection(option.value)}
            >
              {option.text}
              <KeyboardArrowRight fontSize="small" />
            </MenuItem>
          );
        })}
      </Menu>
      <Menu
        anchorEl={sectionAnchorEl}
        open={Boolean(sectionAnchorEl)}
        onClose={() => setSectionAnchorEl(null)}
        elevation={1}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {selectedOption?.children.map(option => (
          <MenuItem
            key={option.value}
            onClick={handleSelectOption(selectedOption.value, option.value)}
          >
            {option.text}
          </MenuItem>
        )) ?? null}
      </Menu>
    </>
  );
};

export default FilterMenu;
