import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';

const AppTab = ({ form, handleChange }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        App Association
      </Typography>
      <Typography variant="body1" gutterBottom>
        Use these fields to make this insight part of an Insight Subscription series.
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="appId"
            label="App ID"
            type="number"
            value={form.appId}
            onChange={handleChange('appId')}
            margin="normal"
            helperText="Will default to Jawbone"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="appXid"
            label="App XID"
            type="number"
            value={form.appXid}
            onChange={handleChange('appXid')}
            margin="normal"
            helperText="Will default to Jawbone"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AppTab;
