import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  formContainer: {
    paddingTop: theme.spacing(2),
  },
  questionsSection: {
    margin: theme.spacing(2, 0),
  },
  saveButtonContainer: {
    padding: theme.spacing(0, 4, 4),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  conditionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(4, 4, 4)
  },
  previewButton: {
    marginLeft: '8px',
  },
  blocklyContainer: {
    marginTop: '32px'
  }
}));
