export const validateCondition = (condition, index, warnings, setWarnings) => {
    var message = validateParenthesis(condition);
    const intConditions = (condition.match(/(\$\?)/g) || []).length;
    if(intConditions > 0){
        message += validateIntConditions(intConditions, condition);
    }
    if(((condition.match(/(\?)/g) || []).length) >= 1){
        message += validateInits(condition);
    }
    const newWarnings = warnings.map((warning, i) => {
        if (i !== index) return warning;
        return message;
    });
    setWarnings(newWarnings);
}

const validateParenthesis = (condition) => {
    var message = "";
    var openP = 0;
    for (let i = 0; i < condition.length; i++) {
        if (condition[i] === '(')
            openP += 1;
        if (condition[i] === ')')
            openP -= 1;
    }
    if(openP !== 0)
        message = "Incomplete parenthetical pairs in conditions."
    return message;
}

const validateIntConditions = (intConditions, condition) => {
    var message = "";
    const regex = /(\s)\$\?(\s)[a-zA-z?&*%|]+(\s)\$\?/g;
    if ((condition.match(regex) || []).length !== intConditions / 2)
        message = "!Missing space in conditions.";
    return message;
}

const validateInits = (condition) => {
    const regex = /(a-Z)*(\s)\?[a-z_0-9]+/g;
    var message = "";
    if(!condition.includes("test") && ((condition.match(regex) || []).length !== 1)){
        message = "!Extra or missing space in conditions.";
        return message;
    }
    var qMarks = (condition.match(/(\?)/g) || []).length;
    if(condition.includes("test") && ((condition.match(regex) || []).length !== qMarks)){
        message = "!Extra or missing space in conditions.";
    }
    return message;
}