import store from 'config/store';
import * as data from 'data/programs';
import isEmpty from 'lodash/isEmpty';
import * as service from 'services/programs';

const requestPrograms = () => ({
  type: data.TYPE_REQUEST_PROGRAMS,
  isFetching: true,
});

const receivePrograms = (json) => ({
  type: data.TYPE_RECEIVE_PROGRAMS,
  isFetching: false,
  programs: json.data.programs,
  currentPage: json.meta.currentPage,
  nextPage: json.meta.nextPage,
});

const receiveProgramsError = (error) => ({
  type: data.TYPE_RECEIVE_PROGRAMS_ERROR,
  isFetching: false,
  error: error.message ? error.message : error
});

export const fetchPrograms =
  (filters = null, page = 1) =>
  (dispatch) => {
    dispatch(requestPrograms());
    return service
      .fetchPrograms(filters, page)
      .then((programs) => dispatch(receivePrograms(programs)))
      .catch((error) => dispatch(receiveProgramsError(error)));
  };

export const fetchProgramsIfNeeded = () => {
  return (dispatch, getState) => {
    const state = store.getState();
    const programs = state.tenants;
    const isFetching = programs.isFetching;
    if (isEmpty(programs.programs) && !isFetching) {
      return dispatch(fetchPrograms());
    }
  };
};
