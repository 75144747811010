import { authorizedAxios } from 'config/axios';
import ModuleFactory from 'factories/modules';

const FACTUARY_URL = '/factuary/api';

export const fetchModules = () => {
  return authorizedAxios.get(`${FACTUARY_URL}/v2/modules`).then((response) => {
    return {
      ...response,
      modules: response.modules.map(ModuleFactory.getContentToModel),
    };
  });
};

export const createModule = (programModule) => {
  return authorizedAxios.post(`${FACTUARY_URL}/v2/modules`, ModuleFactory.toDTO(programModule));
};

export const updateModule = (programModule) => {
  return authorizedAxios.post(`${FACTUARY_URL}/v2/modules`, ModuleFactory.toDTO(programModule));
};

export const fetchModule = (id) => {
  return authorizedAxios.get(`${FACTUARY_URL}/v2/modules/${id}`).then((response) => {
    return {
      ...response,
      programModule: ModuleFactory.toModel(response.module),
    };
  });
};

export const getContent = (query, content_type, type = null) => {
  let URL = `${FACTUARY_URL}/v2/content/filter?per_page=12&page=1&name=${query}&content_type=${content_type}`;
  if (type) {
    URL += `&type=${type}`;
  }
  return authorizedAxios.get(URL).then((response) => {
    return {
      ...response,
      content: response.data.map(ModuleFactory.stepToModel),
    };
  });
};
