import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem',
    alignItems: 'center',
  }
});
