import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  inputFont: {
    fontFamily: 'Monaco, Menlo, "Ubuntu Mono", Consolas, source-code-pro, monospace',
  },
  itemContainer: {
    marginBottom: '26px'
  }
});
