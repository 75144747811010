import { authorizedAxios } from 'config/axios';
import PledgeFactory from 'factories/pledges';

const FACTUARY_URL = '/factuary/api';

/**
 * Service that will request pledges from the factuary api.
 *
 * @param {number} author - filter insights with the id of an author
 * @param {string} query - filter fields by an arbitrary string
 * @param {string} status - filter insights by publication status
 * @return {Promise}
 */
export const fetchPledges = (filters, page = 0) => {
  const params = new URLSearchParams();

  const copyFilters = { ...filters };

  if (copyFilters['searched text']?.length) {
    params.append('title', copyFilters['searched text'].join('|'))
    params.append('name', copyFilters['searched text'].join('|'))
    delete copyFilters['searched text']
  }

  if (copyFilters) {
    Object.entries(copyFilters).forEach(([type, values]) => {
      if (values.length) params.append(type, values.join('|'));
    });
  }

  if (page) params.append('page', page);
  params.append('per_page', 10);

  return authorizedAxios.get(`${FACTUARY_URL}/v2/content/filter?content_type=pledge&${params}`).then(response => {
    return ({
      meta: {
        nextPage: response.meta.next_page,
        currentPage: response.meta.page
      },
      data: {
        pledges: response.data.map(PledgeFactory.toModel)
      }
    })
  });
};

/**
 * Service that will request authors of pledges from the factuary api
 */
export const fetchAuthors = () => {
  return authorizedAxios.get(`${FACTUARY_URL}/v1/pledges/authors`);
};

/**
 * Service that will request a single pledge from the factuary api
 *
 * @param {string} id - the id of the pledge you wish to load
 * @return {Promise}
 */
export const fetchPledge = id => {
  return authorizedAxios.get(`${FACTUARY_URL}/v1/pledges/${id}`).then(response => ({
    ...response,
    data: PledgeFactory.toModel(response.data)
  }));
};

/**
 * Service that will request default pledge strings from factuary api
 *
 * @param {string} type - filter default strings by pledge type
 * @return {Promise}
 */
export const fetchDefaultStrings = type => {
  const filter = type ? `?type=${type}` : '';
  return authorizedAxios.get(`${FACTUARY_URL}/v1/pledges/strings/defaults${filter}`);
};

/**
 * Persist an existing pledge to update it's data
 */
export const updatePledge = pledge => {
  return authorizedAxios.put(`${FACTUARY_URL}/v1/pledges/${pledge.id}`, PledgeFactory.toDTO(pledge));
};

/**
 * Persist a new pledge to update it's data
 */
export const createPledge = pledge => {
  return authorizedAxios.post(`${FACTUARY_URL}/v1/pledges`, PledgeFactory.toDTO(pledge));
};

export const getLanguageFile = (pledgeId, languageCode) => {
  return authorizedAxios.get(`${FACTUARY_URL}/v2/strings/pledge/csv?content_id=${pledgeId}&lang=${languageCode}`);
};

export const getLanguageFileTemplate = (pledgeId) => {
  return authorizedAxios.get(`${FACTUARY_URL}/v2/strings/pledge/csv?content_id=${pledgeId}`)
    .then((response) => response)
    .catch(err => '');
};

export const postLanguageFile = (languageFile, pledgeId, languageCode) => {
  return authorizedAxios.post(`${FACTUARY_URL}/v2/strings/pledge/csv?content_id=${pledgeId}&lang=${languageCode}`, languageFile);
};
