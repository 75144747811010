import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';

import Autocomplete from 'components/commons/autocomplete';
import Select from 'components/commons/select';
import InsightParams from 'components/commons/params-field';
import TenantsField from 'components/commons/tenants-field';
import BlocklyConditionsAndTests from 'components/commons/blockly-conditions-and-tests';
import TestParams from 'components/commons/tests-field/Params';
import { useDebounce } from 'utils/hooks';
import useStyles from 'components/commons/tests-field/TestsField.styles';

const LabelsAndParamsTab = ({ facts, setForm, form, handleChange, handleTenantCheck, labels, tenants, testResults, handleConditions = () => { }, handleBlocklyCondition = () => { } }) => {
  const classes = useStyles();
  const [params, setParams] = useState([]);
  const debouncedParams = useDebounce(params, 500);

  const makeLabelOption = label => {
    if (!label) return null;
    return {
      value: label.id,
      text: label.name
    };
  };

  const labelOptions = Object.values(labels).map(makeLabelOption);
  const factOptions = facts.map(fact => ({ label: fact.value }));

  useEffect(() => {
    if (debouncedParams) {
      handleExternalChange();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);
  const handleExternalChange = () => {
    const newState = {
      conditions: [...form.tests.conditions],
      params: debouncedParams.map(param => ({
        expected: Object.fromEntries(param.expected),
        facts: Object.fromEntries(param.facts),
      })),
    };
    handleChange('tests')({ target: { value: newState } });
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom>
            Labels
          </Typography>
          <Typography variant="body1" gutterBottom>
            Labels contain conditions or rules that control when certain content should display to
            which users.
          </Typography>
          <Select
            label="Select labels"
            id="labels"
            onChange={handleChange('labels')}
            value={form.labels}
            options={labelOptions}
            multiple
            fullWidth
          ></Select>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom>
            Facts
          </Typography>
          <Typography variant="body1" gutterBottom>
            Add all facts to be used in the calculated parameter values.
          </Typography>
          <Autocomplete
            label="facts"
            placeholder="Search for facts"
            suggestions={factOptions}
            initialValue={form.facts}
            onChange={handleChange('facts')}
            margin="normal"
            multiple
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Parameters
          </Typography>
          <Typography variant="body1" gutterBottom>
            {'Parameters will auto-fill; however, you must add formatting instructions & remove '}
            {'unneeded items manually.'}
          </Typography>
          <InsightParams handleChange={handleChange('params')} initialValue={form.params} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Tests for params
          </Typography>
          <TestParams
            {...{
              classes,
              onChange: setParams,
              ...(form.tests && { initialValue: form.tests.params }),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <BlocklyConditionsAndTests
            content={form}
            handleChange={handleConditions}
            blocklyHandleChange={handleBlocklyCondition}
            facts={facts}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <div>
            <TenantsField
              handleTenantCheck={handleTenantCheck}
              initialValue={form.excludeTenants}
              tenantList={tenants}
              form={form}
              setForm={setForm}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default LabelsAndParamsTab;
