import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';

export const LongInsightCard = ({ questions, title, body, handleShow }) => {
  const selectedDevice = document.getElementsByClassName("_1HaoU")[0].innerHTML;
  const dynamicClass = (selectedDevice === "Note 8" || selectedDevice === "Nexus 5" ) ? "text-cardTitle font-black font-gotham" : "text-cardTitle font-black font-gotham line-clamp-3";
  
  return (
    <>
      <div className="flex flex-row justify-between items-center mt-4">
        <div className="flex flex-row">
          <div className="mr-2">
            <EventNoteRoundedIcon className="text-soft-red" fontSize="large" />
          </div>
          <div className="flex flex-col">
            <div className="font-gotham">Smart Coach</div>
            <div className="font-bold font-gotham">Questions: {questions?.length}</div>
          </div>
        </div>
        <button onClick={!window.location.pathname.includes("question") ? handleShow : ()=>null}>
          <CloseIcon fontSize="large"></CloseIcon>
        </button>
      </div>
      <div className="mt-6">
        <div className={dynamicClass}>{title}</div>
      </div>
      <div className="mt-3 text-cardBody2 text-left font-gotham">{body}</div>
    </>
  );
};

export default LongInsightCard;