import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  formContainer: {
    padding: theme.spacing(4)
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    gap: '1rem'
  },
  conditionSection: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  moduleSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem 0'
  },
  moduleList: {
    whiteSpace: 'nowrap',
    listStyleType: 'none',
    padding: '0',
    marginBottom: theme.spacing(4)
  },
  modulesListItem: {
    width: '100%',
    display: 'flex',
    gap: '2rem',
    alignItems: 'center'
  },
  draggableIcon: {
    '&:hover': {
      cursor: 'grab'
    }
  },
  newModuleButton: {
    alignSelf: 'center'
  },
  conditionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  itemContainer: {
    position: 'relative',
  },
  blockContainer: {
    padding: '0 1.3rem',
    margin: '1rem 0',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  expansionSummary: {
    justifyContent: 'flex-start',
    '&>div': {
      flexGrow: '0'
    }
  },
  addBtn: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    zIndex: '10',

    '&::before': {
      height: '1px',
      content: '""',
      position: 'absolute',
      top: '0',
      left: '5%',
      right: '5%',
      width: '90%',
      backgroundImage: 'linear-gradient(to right, transparent, rgb(48, 49, 51), transparent)',
    },
  },
  addIcon: {
    position: 'absolute',
    bottom: '-1.025rem',
    backgroundColor: 'white',
    borderRadius: '50%',
    margin: '0',
    padding: '0.2rem',

    '&:hover': {
      backgroundColor: 'rgb(240,240,240)',
    }
  },
  conflictLink: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    marginRight: '0.25rem',
  },
  conflictContainer: {
    margin: '0 0 1rem'
  },
  conflictIcon: {
    color: 'rgba(225, 196, 66)',
  },
  conflictText: {
    padding: '0 1rem'
  },
  errorItems: {
    width: '100%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
  delayField: {
    width: 'fit-content',
    marginTop: 0
  },
  additionalConditionsContainer: {
    display: 'flex',
    gap: '2rem',
    padding: "16px 0"
  }
}));
