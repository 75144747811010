import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import Insights from 'views/insights';
import { fetchLabelsIfNeeded } from 'actions/labels';
import { fetchInsights } from 'actions/insights';
import * as insightsService from 'services/insights';

const mapStateToProps = state => ({
  insights: state.insights
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        fetchInsights,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchLabelsIfNeeded({ fields: ['id', 'name'] }));
    },
    onUnmount: () => {
    }
  },
  services: {
    fetchAuthors: insightsService.fetchAuthors
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
