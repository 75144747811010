import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    gap: '0',
  },
  expansionPanel: {
    marginTop: '1rem',
  },
  title: {
    margin: 0,
  },
  select: {
    margin: 0,
  },
  dates: {
    display: 'flex',
    padding: '1rem 0',
    gap: '1rem'
  },
  applyBtn: {
    width: '1rem',
    margin: 'auto'
  }
}));