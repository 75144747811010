import React, { useState, useEffect } from 'react';
import ItemTestsField from '../item-tests-field';
import ItemConditionsField from '../item-conditions-field';
import BlocklyConditions from '../blockly-conditions';


const BlocklyConditionsAndTests = ({
  content,
  handleChange,
  conditionsTitle = "Conditions",
  conditionsTipMessage = null,
  testsTitle = "Tests",
  testsTipMessage = null,
  blocklyHandleChange,
  facts
}) => {

  const [updateOptions, setUpdateOptions] = useState(false);
  const [validating, setValidating] = useState(false);
  const [codeToValidate, setCodeToValidate] = useState([]);

  useEffect(() => {
    window.addEventListener('onBlocklyDropdownCreation', handleDropdownCreation, false);
    return () => {
      window.removeEventListener('onBlocklyDropdownCreation', handleDropdownCreation, false);
    };
  });

  const handleDropdownCreation = (e) => {
    e.stopPropagation();
    setUpdateOptions(true);
  };

  return <>
    <ItemConditionsField
      handleChange={handleChange}
      value={content.conditions}
      title={conditionsTitle}
      tipMessage={conditionsTipMessage}
    />
    <ItemTestsField
      handleChange={handleChange}
      value={content.tests}
      title={testsTitle}
      tipMessage={testsTipMessage}
      setValidating={setValidating}
      codeToValidate={codeToValidate}
      form={content}
    />
    <BlocklyConditions
      handleChange={blocklyHandleChange}
      visualConditions={content.visualConditions}
      factOptions={facts.map(fact => ({
        label: fact.value,
        type: fact.type ? fact.type : 'text'
      }))}
      updateOptions={updateOptions}
      setUpdateOptions={setUpdateOptions}
      validating={validating}
      setCodeToValidate={setCodeToValidate}
    />
  </>;
};

export default BlocklyConditionsAndTests;