import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

const DatePicker = ({ label, value, onChange }) => (
  <KeyboardDatePicker
    autoOk
    variant="inline"
    inputVariant="standard"
    label={label}
    format="MM/dd/yyyy"
    value={value}
    InputAdornmentProps={{ position: "start" }}
    onChange={onChange}
    maxDate={new Date()}
  />
);

export default DatePicker;