import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  defaultColor: {
    backgroundColor: 'black'
  },
  whiteText: {
    color: 'white',
    fontWeight: 400,
  },
  logoText: {
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
    whiteSpace: 'pre',
    display : 'flex',
    alignItems : "center"
  },
  navigationItem: {
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1),
    textDecoration: 'none',
    textTransform: 'capitalize',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.palette.grey['A700']
    },
  },
  activeText: {
    color: theme.palette.primary.main
  },
  logo: {
    marginRight: theme.spacing(2),
    height: '30px',
    width: '30px'
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: theme.palette.grey[800],
    margin: `${theme.spacing(2)}px 0`,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3),
    width: '1px'
  },
  headerTitle: {
    display: 'block'
  }
}));
