import React, { useEffect, useState } from 'react';
import SurveyQuestion from 'components/views/surveys/question';
import { NEW_SURVEY_QUESTION, NEW_SURVEY_QUESTION_OPTION } from 'data/surveys';
import { isEmpty, padStart } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { idGenerator } from 'utils/generator';
import { useDebounce } from 'utils/hooks';

import { Button } from '@material-ui/core';

import useStyles from './Questions.styles';

const sortQuestion = (questionA, questionB) => {
  return questionA.position - questionB.position;
};

const Questions = ({ displayAddQuestion = true, initialValue, surveyName, onChange, surveyId }) => {
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const debouncedQuestions = useDebounce(questions, 500);
  const [questionsOrder, setQuestionsOrder] = useState(questions);
  const [showOnlyInput, setShowOnlyInput] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (!isEmpty(initialValue) && !gotInitialValue) {
      setQuestions(initialValue);
      setGotInitialValue(true);
    }
  }, [initialValue, gotInitialValue]);

  useEffect(() => {
    if (debouncedQuestions) {
      onChange({ target: { value: questions } });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuestions]);
  useEffect(() => {
    setQuestionsOrder(questions);
  }, [questions]);
  const handleChange = (questionKey) => (value) => {
    let newQuestions = questions;
    newQuestions[questionKey] = value;
    setQuestions(newQuestions);
  };

  const handlePosition = (name, pos, newPos) => {
    let newQuestions = questions.map((q) => orderMapQuestions(q, pos, newPos, name));
    setQuestions(newQuestions);
  };

  const orderMapQuestions = (question, pos, newPos, name) => {
    if (
      newPos > pos &&
      question.position <= newPos &&
      question.position > pos &&
      question.name !== name
    ) {
      question.position -= 1;
    } else if (
      newPos < pos &&
      question.position >= newPos &&
      question.position < pos &&
      question.name !== name
    ) {
      question.position += 1;
    }
    return question;
  };

  const handleAddQuestion = () => {
    const nextQuestion = `${surveyName}_${padStart(questions.length + 2, 3, '0')}`;
    const newQuestion = {
      ...NEW_SURVEY_QUESTION,
      position: questions.length + 1,
      internalId: idGenerator.next().value,
      name: `${surveyName}_${padStart(questions.length + 1, 3, '0')}`,
      defaultNextQuestion: nextQuestion,
      options: [{ ...NEW_SURVEY_QUESTION_OPTION, nextQuestion }],
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleDeleteQuestion = (deleteKey) => () => {
    const newQuestions = questions.filter((_, questionKey) => questionKey !== deleteKey);
    setQuestions(newQuestions);
  };

  const handleOnDragStart = (result) => {
    const questionSelected = questionsOrder.filter(
      (question) => question.internalId === result.draggableId
    );
    setSelectedQuestion(questionSelected[0].position);
    setShowOnlyInput(false);
    setIsDragging(true);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(questionsOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const itemsReordered = items.map((question, ind) => {
      question.position = ind;
      return question;
    });
    setQuestionsOrder(itemsReordered);
    setQuestions(itemsReordered);
    const questionSelected = questionsOrder.filter(
      (question) => question.internalId === result.draggableId
    );
    setSelectedQuestion(questionSelected[0].position);
    setIsDragging(false);
  };

  const renderQuestion = (question, questionKey) => {
    const isSelected = questionKey === selectedQuestion;
    const nextQuestion = `${surveyName}_${padStart(questionKey + 2, 3, '0')}`;
    return (
      <Draggable key={question.internalId} draggableId={question.internalId} index={questionKey}>
        {(provided) => (
          <li
            className={classes.li}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            id={question.id}
          >
            <SurveyQuestion
              surveyId={surveyId}
              key={question.internalId}
              size={questions.length}
              onChange={handleChange(questionKey)}
              onChangePosition={handlePosition}
              onClick={() => setSelectedQuestion(questionKey)}
              initialValue={question}
              isSelected={isSelected}
              nextQuestion={nextQuestion}
              showOnlyInput={showOnlyInput}
              isDragging={isDragging}
              {...(displayAddQuestion && { onDelete: handleDeleteQuestion(questionKey) })}
            />
          </li>
        )}
      </Draggable>
    );
  };
  return (
    <>
      <DragDropContext
        onBeforeCapture={() => setShowOnlyInput(true)}
        onDragStart={handleOnDragStart}
        onDragEnd={handleOnDragEnd}
      >
        <Droppable droppableId="questions">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef} className={classes.ul}>
              {questionsOrder.sort(sortQuestion).map(renderQuestion)}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      {displayAddQuestion && (
        <div className={classes.addQuestionContainer}>
          <Button color="primary" onClick={handleAddQuestion}>
            Add Question
          </Button>
        </div>
      )}
    </>
  );
};

export default Questions;
