import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { Home } from 'views';
import fetchTenantsIfNeeded from '../../../actions/tenants';

const mapStateToProps = ({ tenants }) => ({ tenants });

const mapDispatchToProps = dispatch => ({
    actions: {
    ...bindActionCreators(
        {
            push,
        },
        dispatch
        ),
      onMount: () => {
        dispatch(fetchTenantsIfNeeded());
      },
    }
  });
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
