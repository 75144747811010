import React from 'react';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from './Survey.styles';
import { highlighter } from '../../../../utils/columns';

const Survey = ({ actions, survey, searchedText }) => {
  const classes = useStyles();

  // const questionsPlural = survey.questions.length === 1 ? 'questions' : 'question';
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">{highlighter(searchedText, survey.name)}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={classes.infoContainer}>
          <div>
            <Typography className={classes.boldText} variant="body2" display="inline">
              Type:{' '}
            </Typography>
            <Typography variant="body2" display="inline">
              {survey.type}
            </Typography>
          </div>
          <div>
            <Typography className={classes.boldText} variant="body2" display="inline">
              State:{' '}
            </Typography>
            <Typography variant="body2" display="inline">
              {survey.status}
            </Typography>
          </div>
          {/* // TODO: This is pending on backend */}
          {/* <div>
            <Typography className={classes.boldText} variant="body2" display="inline">
              Questions:{' '}
            </Typography>
            <Typography
              variant="body2"
              display="inline"
            >{`${survey.questions.length} ${questionsPlural}`}</Typography>
          </div> */}
          <Typography variant="h6">{highlighter(searchedText, survey.introHeader)}</Typography>
          <Typography variant="body1">{highlighter(searchedText, survey.introBody)}</Typography>
        </div>
      </ExpansionPanelDetails>
      <ExpansionPanelActions>
        <Button size="small" onClick={() => actions.push(`/surveys/${survey.id}/edit`)}>
          Edit
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
};

export default Survey;
