import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  description: {
    marginBottom: 25,
  },
  summary : {
      flexDirection : "column"
  },
  details  : {
    flexDirection : "column"
}
});
