import React, { useState } from 'react';
import ABTestingAndGrouping from 'components/commons/ab-testing-and-grouping';
import { UserCohortException } from 'components/commons/blockly-conditions/utils/index';
import Button from 'components/commons/button';
import Form from 'components/commons/form';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { hasDuplicates } from 'utils/commons';
import { openInNewTab } from 'utils/navigation';

import { AppBar, Box, Container, Paper, Tab, Tabs, Typography } from '@material-ui/core';

import useStyles from './Form.styles';
import GeneralForm from './GeneralForm';
import QuestionsForm from './QuestionsForm';
import TranslationForm from './TranslationForm';

const ABTestingAndGroupingDivTab = (props) => {
  return (
    <Box p={4}>
      <ABTestingAndGrouping {...props} />
    </Box>
  );
};

const tabs = [GeneralForm, QuestionsForm, TranslationForm, ABTestingAndGroupingDivTab];

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {children}
  </Typography>
);

const SurveyForm = ({
  form,
  setForm,
  handleChange,
  handleFileDownload,
  handleTemplateFileDownload,
  handleFileUpload,
  handleStatusChange,
  handleTenantCheck,
  tenants,
  onSubmit,
  nonEditableName = false,
  testResults,
  facts
}) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, newValue) => setTabValue(newValue);

  const handleConditions =
    (name) =>
      ({ target: { value } }) => {
        if (value != null) {
          setForm({
            ...form,
            [name]: value
          });
        }
      };
  const handleBlocklyCondition = (code, diff, XML, clear = false) => {
    const newForm = cloneDeep(form);
    if (XML?.childNodes?.length > 0) {
      newForm.visualConditions = new XMLSerializer().serializeToString(XML);
    }

    if (clear) {
      newForm.conditions = [];
      newForm.visualConditions = '<xml></xml>';
    }

    if (code) {
      const newConditions = code;
      var aliasArray = [];
      newConditions.forEach(condition => {
        if (condition.includes('user_cohort')) {
          aliasArray = aliasArray.concat(condition.match(/\?\w+&/g));
        }
      });

      if (hasDuplicates(aliasArray)) {
        throw new UserCohortException();
      }
      newForm.conditions = newConditions;
    }

    setForm(newForm);
  };


  const handleSubmit = () => {
    if (form.type === '8') {
      ['introHeader', 'introBody', 'outroHeader', 'outroBody'].forEach(field =>
        handleChange(field)({ target: { value: '' } })
      );
    }
    onSubmit();
  };

  const a11yProps = index => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  });

  const handleContentPreview = () => {
    openInNewTab(`/previews/content/surveys/${form.id}`);
  };

  const isSingleTypeSurvey = form.type === '8';
  return (
    <Container maxWidth="md" className={classes.formContainer}>
      <Form onSubmit={handleSubmit}>
        <Paper>
          <AppBar className={classes.tabs} elevation={0} position="static">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="General" {...a11yProps(2)} />
              <Tab label="Questions" {...a11yProps(1)} />
              <Tab label="Translations" {...a11yProps(3)} />
              <Tab label="AB Testing & Grouping" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          {tabs.map((TabComponent, index) => (
            <TabPanel key={index} value={tabValue} index={index}>
              <TabComponent
                form={form}
                setForm={setForm}
                handleChange={handleChange}
                handleConditions={handleConditions}
                handleBlocklyCondition={handleBlocklyCondition}
                handleTenantCheck={handleTenantCheck}
                handleFileDownload={handleFileDownload}
                handleTemplateFileDownload={handleTemplateFileDownload}
                handleStatusChange={handleStatusChange}
                handleFileUpload={handleFileUpload}
                isSingleType={isSingleTypeSurvey}
                tenants={tenants.tenants}
                nonEditableName={nonEditableName}
                testResults={testResults}
                type="survey"
                facts={facts.facts}
              />
            </TabPanel>
          ))}
          <section className={classes.saveButtonContainer}>
            <Button color="primary" variant="contained" type="submit" size="medium">
              Save
            </Button>
            {form.id && (
              <Button
                color="primary"
                variant="contained"
                size="medium"
                onClick={handleContentPreview}
                className={classes.previewButton}
              >
                Preview
              </Button>
            )}
          </section>
        </Paper>
      </Form>
    </Container>
  );
};

SurveyForm.propTypes = {
  form: PropTypes.object,
  handleChange: PropTypes.func,
  handleFileDownload: PropTypes.func,
  handleTemplateFileDownload: PropTypes.func,
  handleStatusChange: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleTenantCheck: PropTypes.func,
  tenants: PropTypes.object,
  testResults: PropTypes.array,
  onSubmit: PropTypes.func
};

export default SurveyForm;
