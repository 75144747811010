import * as Msal from 'msal';

import UserAPI  from './shared/user';
import parseJwtToken from './shared/parseJwtToken';


const appConfig = {
  b2cScopes: [process.env.REACT_APP_AZURE_B2C_SCOPE, 'openid'],
};

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_B2C_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_B2C_AUTHORITY,
    validateAuthority: false,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const myMSAL = new Msal.UserAgentApplication(msalConfig);

const loginRequest = {
  scopes: appConfig.b2cScopes,
};
const  UserApiCreate= new UserAPI();

 export const login = () => {
  return new Promise(async (resolve, reject)=>{
    return myMSAL
    .loginPopup(loginRequest)
    .then(loginResponse => {
      return UserApiCreate.azureLogin(loginResponse.idToken.rawIdToken);
    })
    .then(({ token }) => {
       const tokenData = parseJwtToken(token);
       const userData =  {
        isAuthed: true,
        authTime: Date.now(),
        uid: tokenData.id.uid,
        exp: tokenData.exp,
        token: token
      };
     
      resolve(userData);
    })
    .catch(err => {
      console.error(err);
      reject('Wrong credentials');
    });

  })
 }
 
 