import * as service from 'services/insights';
import {
  TYPE_REQUEST_INSIGHTS,
  TYPE_RECEIVE_INSIGHTS,
  TYPE_RECEIVE_INSIGHTS_ERROR,
} from 'data/insights';

const requestInsights = () => ({
  type: TYPE_REQUEST_INSIGHTS,
  isFetching: true
});

const receiveInsights = json => ({
  type: TYPE_RECEIVE_INSIGHTS,
  isFetching: false,
  insights: json.data.insights,
  currentPage: json.meta.currentPage,
  nextPage: json.meta.nextPage
});

const receiveInsightsError = error => ({
  type: TYPE_RECEIVE_INSIGHTS_ERROR,
  error: error,
  isFetching: false
});

export const fetchInsights = (filters = null, page = 1) => dispatch => {
  dispatch(requestInsights());
  return service
    .fetchInsights(filters, page)
    .then(insights => dispatch(receiveInsights(insights)))
    .catch(error => dispatch(receiveInsightsError(error)));
};