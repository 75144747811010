import React, { useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';

import { Clips } from 'components/commons/code';
import { useDebounce } from 'utils/hooks';
import { dateToMinutesToday, minutesToDateToday } from 'utils/pledges';

const ClipsTimeSwappable = ({ name, onChange, initialValue }) => {
  const [showClips, setShowClips] = useState(false);
  const [gotInitialProps, setGotInitialProps] = useState(false);
  const [value, setValue] = useState(new Date());
  const [sendValue, setSendValue] = useState('0');
  const debouncedValue = useDebounce(sendValue, 500);
  useEffect(() => {
    if (initialValue != null && !gotInitialProps) {
      if (!isNaN(parseInt(initialValue))) {
        const newValue = minutesToDateToday(initialValue);
        setValue(newValue);
        setSendValue(parseInt(initialValue));
      } else {
        setValue(initialValue);
        setSendValue(initialValue);
        setShowClips(true);
      }
      setGotInitialProps(true);
    }
  }, [initialValue, gotInitialProps]);

  useEffect(() => {
    if (debouncedValue != null) {
      handleExternalChange();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleSwap = () => {
    if (!showClips) {
      const value = '';
      setValue(value);
      setSendValue(value);
    } else {
      const value = new Date();
      setValue(value);
      setSendValue(dateToMinutesToday(value));
    }
    setShowClips(!showClips);
  };

  const handleTimeChange = value => {
    const date = dateToMinutesToday(value);
    setValue(value);
    setSendValue(date);
  };

  const handleClipsChange = value => {
    setValue(value);
    setSendValue(value);
  };

  const handleExternalChange = () =>
    onChange({
      target: { value: debouncedValue }
    });

  return (
    <>
      {showClips ? (
        <Clips name={name} value={value} onChange={handleClipsChange} multiline />
      ) : (
        <TimePicker
          autoOk
          label="12 hours"
          value={value}
          onChange={handleTimeChange}
          fullWidth
          minutesStep={1}
        />
      )}
      <Box mt={1}>
        <Button onClick={handleSwap}>Swap input type</Button>
      </Box>
    </>
  );
};

export default ClipsTimeSwappable;
