import React, { useEffect } from 'react';
import 'sanity-mobile-preview/dist/index.css';

import './index.css';

import { InsightCard, PledgeCard, SurveyCard } from 'components/commons';
import { MobileLayout } from 'components/views/mobile-layout';
import LongCardLayout from 'components/views/mobile-layout/long-card-layout';
import { useLocation } from 'react-router';
import MobileDevicePreview from 'sanity-mobile-preview';

import useStyles from './Previews.styles';

const Previews = ({ actions, insight, pledge, survey }) => {
  const classes = useStyles();
  const location = useLocation();
  let path = location.pathname.split('/');
  let contentType = path[3]
  let contentId = path[4]
  let questionId = path[6];

  useEffect(() => {
    let newInterval = setInterval(() => {
      let myButtons = document.querySelectorAll('._1HaoU');
      for (let item of myButtons) {
        item.style.height = '45px';
        item.style.width = '210px';
      }
      if (newInterval) {
        clearInterval(newInterval);
      }
    });
  }, []);

  useEffect(() => {
    switch (contentType) {
      case 'insights':
        actions.fetchInsight(contentId);   
        break
      case 'pledges':
        actions.fetchPledge(contentId);
        break
      case 'surveys':
        actions.fetchSurvey(contentId);
        break
      default:
        break;
    }
  }, [contentId, actions, contentType]);

  const switchVariant = () => {
    switch (contentType) {
      case 'insights':
        return (
          <InsightCard
            title={insight.insight.title}
            body={insight?.insight?.body}
            type={insight?.insight?.type}
            bodyHtml={insight?.insight?.bodyHtml}
            referencesHtml={insight?.insight?.referencesHtml}
          />
        );
      case 'pledges':
        return (
          <PledgeCard 
            title={pledge.pledge.name}
            body={pledge.pledge.body}
            acceptText={pledge.pledge.acceptText}
            declineText={pledge.pledge.declineText}
          />
        );
      case 'surveys':
        if (questionId) {
          return (
            <LongCardLayout
              show={true}
              setShow={() => {}}
              title={survey.survey.introHeader}
              questions={survey?.survey?.questions}
              body={survey.survey.introBody}
              questionId={questionId}
            />
          );
        } else {
          return (
            <SurveyCard
              title={survey.survey.introHeader}
              questions={survey?.survey?.questions}
              body={survey.survey.introBody}
            />
          );
        }
        
      default:
        break;
    }
  }

  return (
    <div className={classes.tracksContainer}>
      <div className={classes.scale}>
        <MobileDevicePreview
          preSelectedDevice={'iphone-x'}
          preSelectedLandscape={false}
          preSelectedColor={'black'}
        >
          <MobileLayout child={switchVariant()} />
        </MobileDevicePreview>
      </div>
    </div>
  );
};

export default Previews;
