import * as Blockly from 'blockly/core';

var numberFacts = {
    "type": "dropdown_number_facts",
    "message0": "Fact: %1",
    "colour": 180,
    "args0": [{
        "type": "field_dropdown",
        "name": "fact",
        "options": [
            ["account_age_day", 'account_age_day'],
        ]
    }],
    "output": "Number",
    "tooltip": "Number fact",
};

Blockly.Blocks['dropdown_number_facts'] = {
    init: function() {
        this.jsonInit(numberFacts);
        const event = new Event('onBlocklyDropdownCreation');
        window.dispatchEvent(event);
    }
};

var textFacts = {
    "type": "dropdown_text_facts",
    "message0": "Fact: %1",
    "colour": 40,
    "args0": [{
        "type": "field_dropdown",
        "name": "fact",
        "options": [
            ["account_age_day", 'account_age_day'],
        ]
    }, ],
    "output": null,
    "tooltip": "Text fact",
};

Blockly.Blocks['dropdown_text_facts'] = {
    init: function() {
        this.jsonInit(textFacts);
        const event = new Event('onBlocklyDropdownCreation');
        window.dispatchEvent(event);
    }
};

var userCohort = {
    "type": "user_cohort",
    "message0": "user_cohort %1 & %2",
    "colour": 360,
    "inputsInline": true,
    "args0": [{
            "type": "input_value",
            "name": "alias",
            "check": "String"
        },
        {
            "type": "input_value",
            "name": "value",
            "check": "String"
        },
    ],
    "output": null,
    "tooltip": "user_cohort <alias> <value>",
};

Blockly.Blocks['user_cohort'] = {
    init: function() {
        this.jsonInit(userCohort);
    }
};