import React, { useState, useEffect } from 'react'
import { RoundedButton } from 'components/commons';

import SportsOutlinedIcon from '@material-ui/icons/SportsOutlined';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import { CircularProgress, Box } from '@material-ui/core';


export const InsightCard = ({
    title = '',
    body = '',
    type = '0',
    bodyHtml = '',
    referencesHtml = ''
}) => {
  const [insightState, setInsightState] = useState(false)
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const handleInsightState = () => {
    setInsightState(!insightState)
  }
  if(loading){
    return <Box sx={{ display: 'flex', position: 'absolute', top: '50%', left : "47%" }}>
    <CircularProgress />
  </Box>
  }
  const selectedDevice = document.getElementsByClassName("_1HaoU")[0].innerHTML;
  const dynamicClass = (selectedDevice === "Note 8" || selectedDevice === "Nexus 5" ) ? "text-cardTitle font-black font-gotham" : "text-cardTitle font-black font-gotham line-clamp-3";
    return (
      <div className="flex flex-col rounded-2xl shadow-lg mx-3 p-6 bg-white">
        <div className="flex flex-row items-center">
          <div className="mr-2">
            <SportsOutlinedIcon
              className="text-mostly-desaturated-dark-blue"
              fontSize="large"
            ></SportsOutlinedIcon>
          </div>
          <div className="flex flex-col">
            <div className="font-gotham">Smart Coach</div>
            <div className="font-bold font-gotham">System Tutorial</div>
          </div>
        </div>
        <div className="mt-6">
          <div className={dynamicClass}>{title}</div>
        </div>
        <div className="mt-3 text-cardBody font-gotham pr-4">{body}</div>
        {!insightState && type === '1' ? (
          <div className="mt-14 flex flex-row justify-center">
            <RoundedButton text={'Read More'} action={handleInsightState} variant></RoundedButton>
          </div>
        ) : (
          <div > 
            <div className="px-3.5">
            <div className="mt-3 text-cardBody font-gotham pr-4" dangerouslySetInnerHTML={{ __html: bodyHtml }}></div>
            <div className="mt-3 text-cardBody font-gotham pr-4" dangerouslySetInnerHTML={{ __html: referencesHtml }}></div>
            </div>
          <div className="mt-14 flex flex-row justify-end">
            <div className="mr-4">
              <ThumbUpAltOutlinedIcon></ThumbUpAltOutlinedIcon>
            </div>
            <div>
              <ThumbDownAltOutlinedIcon></ThumbDownAltOutlinedIcon>
            </div>
          </div>
          </div>
        )}
      </div>
    );
}

export default InsightCard;