import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import capitalize from 'lodash/capitalize';
import {
  FilterList as FilterIcon,
  Refresh as RefreshIcon,
  RemoveCircleOutline as RemoveCircleIcon
} from '@material-ui/icons';
import {
  Button,
  Chip,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';

import Body from './Body';
import Form from 'components/commons/form';
import FilterMenu from 'components/views/columns/filter-menu';
import DateFilter from '../date-filter';

import useStyles from './Column.styles';

const SEARCH_FILTER = "searched text";
const DATE_FILTER = "date";

const RemoveColumnButton = ({ onClick }) => (
  <Tooltip title="Remove Column" aria-label="remove-column">
    <IconButton onClick={onClick}>
      <RemoveCircleIcon />
    </IconButton>
  </Tooltip>
);

const RefreshColumnButton = ({ onClick }) => (
  <Tooltip title="Refresh Column" aria-label="refresh-column">
    <IconButton onClick={onClick}>
      <RefreshIcon />
    </IconButton>
  </Tooltip>
);

const FilterColumnButton = ({ id, onClick }) => (
  <Tooltip title="Filter Column" aria-label="filter-column">
    <IconButton id={id} onClick={onClick}>
      <FilterIcon />
    </IconButton>
  </Tooltip>
);

const Column = ({ name, actions, column, service, menuOptions, cardBuilder }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [form, setForm] = useState({
    highlightedText: '',
    searchText: ''
  });
  const [clear, setClear] = useState(false);

  const classes = useStyles();
  useEffect(() => {
    actions.fetchColumnData({ name, columnId: column.id, filters: column.filters, fetchData: service });
    const initSearch = column.filters[SEARCH_FILTER]?.length ? column.filters[SEARCH_FILTER][0] : null;
    if (initSearch) {
      setForm({
        searchText: initSearch,
        highlightedText: initSearch
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptySearchFilter = () => {
    const { filters } = column;
    const newFilters = { ...filters, [SEARCH_FILTER]: [] };
    actions.updateFilters({ name, columnId: column.id, filters: newFilters });
  };

  const handleAddFilter = newFilter => {
    const { filters } = column;
    setClear(false);
    // Don't add an existing filter
    if (filters[newFilter.type]?.includes(newFilter.value)) {
      return;
    }
    const newColumn = {
      name,
      columnId: column.id
    };

    switch (newFilter.type) {
      case SEARCH_FILTER:
        newColumn.filters = {
          ...filters,
          [newFilter.type]: [newFilter.value]
        };
        break;
      case DATE_FILTER:
        const dates = newFilter.value;
        const datesKeys = Object.keys(dates);
        newColumn.filters = {
          ...filters,
        };
        newColumn.formattedFilters = {
          ...filters,
        };
        if (datesKeys[0]) {
          newColumn.filters[datesKeys[0]] = [dates[datesKeys[0]]];
          newColumn.formattedFilters[datesKeys[0]] = [new Date(dates[datesKeys[0]] * 1000).toLocaleDateString('en-us')];
        }
        if (datesKeys[1]) {
          newColumn.filters[datesKeys[1]] = [dates[datesKeys[1]]];
          newColumn.formattedFilters[datesKeys[1]] = [new Date(dates[datesKeys[1]] * 1000).toLocaleDateString('en-us')];
        }
        break;

      default:
        const oldValues = filters[newFilter.type] ? filters[newFilter.type] : [];
        newColumn.filters = {
          ...filters,
          [newFilter.type]: [...oldValues, newFilter.value]
        };
        break;
    };
    actions.updateFilters(newColumn);
    actions.fetchColumnData({ ...newColumn, fetchData: service });
  };

  const handleRemoveFilter = (type, value) => () => {
    const { filters } = column;
    let newFilters = {
      ...filters,
      [type]: [...filters[type].filter(filterValue => filterValue !== value)]
    };
    if (type === SEARCH_FILTER) {
      setForm({ highlightedText: '', searchText: '' });
    }
    if (type.includes("time")) {
      const indexName = type.indexOf("_");
      const typeName = type.substring(indexName + 1);
      Object.keys(newFilters).forEach(key => {
        if (key.includes(typeName)) {
          delete newFilters[key];
        }
      });
      setClear(true);
    }
    actions.updateFilters({ name, columnId: column.id, filters: newFilters });
    actions.fetchColumnData({ name, columnId: column.id, filters: newFilters, fetchData: service });
  };

  const handleRemoveColumn = () => {
    actions.removeColumn(name, column.id);
  };

  const handleUpdate = () => {
    actions.fetchColumnData({ name, columnId: column.id, filters: column.filters, fetchData: service });
  };

  const handleLoadMore = () => {
    actions.fetchColumnData({ name, columnId: column.id, filters: column.filters, page: column.nextPage, fetchData: service, });
  };

  const formatLabel = (filterType, value) => {
    if (filterType.includes("_time_")) {
      return `${capitalize(filterType.replaceAll("_", " "))}: ${new Date(value * 1000).toLocaleDateString('en-us')}`;
    }
    return `${capitalize(filterType)}: ${capitalize(value)}`;
  };

  const { data, isFetching, error, nextPage } = column;

  const openMenu = Boolean(menuAnchorEl);
  const menuId = openMenu ? 'simple-popover' : undefined;

  const filters = Object.entries(column.filters);

  return (
    <div className={classes.column}>
      <Paper className={classes.headerContainer} elevation={1} square={true}>
        <div className={classes.titleContainer}>
          <Typography variant="h5" color="textPrimary">
            {column.name}
          </Typography>
          <section>
            <RemoveColumnButton onClick={handleRemoveColumn} />
            <RefreshColumnButton onClick={handleUpdate} />
            <FilterColumnButton id={menuId} onClick={(e) => setMenuAnchorEl(e.currentTarget)} />
          </section>
          <FilterMenu
            id={menuId}
            anchorEl={menuAnchorEl}
            open={openMenu}
            onClose={() => setMenuAnchorEl(null)}
            onSelected={handleAddFilter}
            menuOptions={menuOptions}
          />
        </div>
        {filters.length ? (
          <div>
            {filters.map(([filterType, filterValues]) =>
              filterValues.map((value, i) => (
                <Chip
                  key={i}
                  className={classes.filterChip}
                  label={formatLabel(filterType, value)}
                  onDelete={handleRemoveFilter(filterType, value)}
                />
              ))
            )}
          </div>
        ) : null}
        <Form
          className={classes.searchBox}
          onSubmit={() => {
            emptySearchFilter();
            setForm({ ...form, highlightedText: form.searchText });
            handleAddFilter({ type: SEARCH_FILTER, value: form.searchText });
          }}
        >
          <TextField
            id="title"
            label="Search in title or body"
            type="text"
            margin="dense"
            fullWidth
            onChange={({ currentTarget: { value } }) => setForm({ ...form, searchText: value })}
            disabled={isFetching}
            required
            value={form.searchText}
            size="small"
          />
          <Button
            className={classes.applyBtn}
            color="primary"
            variant="contained"
            size="small"
            type="submit"
            disabled={isFetching}
          >
            Search
          </Button>
        </Form>
        <DateFilter handleApply={handleAddFilter} clear={clear} />
      </Paper>
      <section className={classes.bodyContainer}>
        <Body
          {...{
            error,
            isFetching,
            data,
            highlightedText: form.highlightedText,
            cardBuilder,
            nextPage,
            handleLoadMore,
          }}
        />
      </section>
    </div>
  );
};


export default Column;