import { connect } from 'react-redux';
import { fetchInsight } from 'actions/insight';
import { fetchPledge } from 'actions/pledge';
import { fetchSurvey } from 'actions/survey'
import { bindActionCreators } from 'redux';
import Previews from 'views/previews';

const mapStateToProps = (state) => ({
  insight: state.insight,
  pledge: state.pledge,
  survey: state.survey
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        fetchInsight,
        fetchPledge,
        fetchSurvey
      },
      dispatch
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Previews);
