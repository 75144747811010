import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  infoContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '2rem',
  },
  update: {
    position: 'absolute',
    right: '65px',
    bottom: '7px',
  },
  status: {
    width: '10rem',
  },
  descriptionSection: {
    flex: '2',
    whiteSpace: 'normal',
    marginRight: theme.spacing(1)
  },
  tableSection: {
    width: '100%'
  }
}));
