import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  formContainer: {
    padding: theme.spacing(4)
  },
  tracksContainer: {
    marginTop: theme.spacing(2)
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(8),
    gap: '1rem'
  },
  draggableIcon: {
    '&:hover': {
      cursor: 'grab'
    }
  },
  selectorContainer: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  selector: {
    width: '20rem'
  },
  conditionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  additionalConditionsContainer: {
    display: 'flex',
    gap: '2rem',
    padding: "16px 0"
  },
  typeSelector: {
    minWidth: '5rem',
    marginBottom: '18px'
  },
  menuIcon: {
    marginBottom: '0.5rem',
    '&:hover': {
      cursor: 'grab'
    }
  },
  contentList: {
    whiteSpace: 'nowrap',
    listStyleType: 'none',
    padding: '0',
    marginBottom: theme.spacing(4)
  },
  contentListItem: {
    width: '100%',
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
  },
  itemContainer: {
    position: 'relative',
  },
  blockContainer: {
    padding: '0 1.3rem',
    margin: '1rem 0',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  expansionSummary: {
    justifyContent: 'flex-start',
    '&>div': {
      flexGrow: '0'
    }
  },
  addBtn: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    zIndex: '10',

    '&::before': {
      height: '1px',
      content: '""',
      position: 'absolute',
      top: '0',
      left: '5%',
      right: '5%',
      width: '90%',
      backgroundImage: 'linear-gradient(to right, transparent, rgb(48, 49, 51), transparent)',
    },
  },
  addIcon: {
    position: 'absolute',
    bottom: '-1.025rem',
    backgroundColor: 'white',
    borderRadius: '50%',
    margin: '0',
    padding: '0.2rem',

    '&:hover': {
      backgroundColor: 'rgb(240,240,240)',
    }
  },
  programItem: {
    display: 'grid',
    gridTemplateColumns: '80% 20%',
    gap: '0.5rem',
    width: '25%',
    alignItems: 'center'
  },
  programItemEdit: {
    width: 'fit-content',
    height: 'fit-content'
  },
  saveBtn: {
    margin: theme.spacing(1)
  },
  triggerSelector: {
    width: 'fit-content',
    marginTop: 0
  },
  delayField: {
    width: 'fit-content',
    marginTop: 0
  }
}));
