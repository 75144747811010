import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {
  Container,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import TestParams from 'components/commons/tests-field/Params';
import Autocomplete from 'components/commons/autocomplete';
import Button from 'components/commons/button';
import Form from 'components/commons/form';
import Select from 'components/commons/select';
import ParamsField from 'components/commons/params-field';
import BlocklyConditionsAndTests from 'components/commons/blockly-conditions-and-tests';
import { hasDuplicates } from 'utils/commons';
import { UserCohortException } from 'components/commons/blockly-conditions/utils/index';
import TenantsField from 'components/commons/tenants-field';
import { tenantHandler } from 'services/shared/handlers';
import { INSIGHT_CATEGORIES, INSIGHT_STATUSES } from 'data/insights';
import { useDebounce } from 'utils/hooks';

import useStyles from './Form.styles';

const ObservationForm = ({ facts, form, setForm, handleChange, labels, onSubmit, tenants, nonEditableName = false }) => {
  const classes = useStyles();
  const [params, setParams] = useState([]);
  const debouncedParams = useDebounce(params, 500);
  useEffect(() => {
    if (debouncedParams) {
      handleExternalChange();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);
  const handleExternalChange = () => {
    const newState = {
      conditions: [...form.tests.conditions],
      params: debouncedParams.map(param => ({
        expected: Object.fromEntries(param.expected),
        facts: Object.fromEntries(param.facts),
      })),
    };
    handleChange('tests')({ target: { value: newState } });
  };
  const handleConditions =
    (name) =>
      ({ target: { value } }) => {
        if (value != null) {
          setForm({
            ...form,
            [name]: value
          });
        }
      };

  const handleBlocklyCondition = (code, diff, XML, clear = false) => {
    const newForm = cloneDeep(form);
    if (XML?.childNodes?.length > 0) {
      newForm.visualConditions = new XMLSerializer().serializeToString(XML);
    }

    if (clear) {
      newForm.conditions = [];
      newForm.visualConditions = '<xml></xml>';
    }

    if (code) {
      const newConditions = code;
      var aliasArray = [];
      newConditions.forEach(condition => {
        if (condition.includes('user_cohort')) {
          aliasArray = aliasArray.concat(condition.match(/\?\w+&/g));
        }
      });

      if (hasDuplicates(aliasArray)) {
        throw new UserCohortException();
      }
      newForm.conditions = newConditions;
    }

    setForm(newForm);
  };

  const handleSubmit = () => onSubmit();

  const handleTenantCheck = (tenantIndex) => ({ target }) => {
    tenantHandler(tenantIndex, target, form, { tenants }, setForm);
  };

  const makeLabelOption = label => {
    if (!label) return null;
    return {
      value: label.id,
      text: label.name
    };
  };

  const labelOptions = Object.values(labels.labels).map(makeLabelOption);
  const factOptions = facts.facts.map(fact => ({ label: fact.value }));

  return (
    <Container maxWidth="lg" className={classes.formContainer}>
      <Paper>
        <Form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                id="name"
                label="Name"
                type="text"
                value={form.name}
                onChange={handleChange('name')}
                disabled={nonEditableName}
                InputProps={{
                  readOnly: nonEditableName
                }}
                margin="normal"
                fullWidth
                required
                inputProps={{
                  maxLength: 150,
                  minLength: 5,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                label="Category"
                id="category"
                onChange={handleChange('category')}
                value={form.category}
                options={INSIGHT_CATEGORIES}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                label="Status"
                id="status"
                onChange={handleChange('status')}
                value={form.status}
                options={INSIGHT_STATUSES}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                id="priority"
                label="Priority"
                type="number"
                value={form.priority}
                onChange={handleChange('priority')}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Value between 0 and 200." placement="top-start">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="ttl"
                label="Time To Live (TTL)"
                type="number"
                value={form.ttl || ''}
                onChange={handleChange('ttl')}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Amount of time in days the insight should be displayed."
                        placement="top-start"
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="cooldown"
                label="Cooldown"
                type="number"
                value={form.cooldown || ''}
                onChange={handleChange('cooldown')}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Amount of time in days between showing the insight for the first time, and showing it again."
                        placement="top-start"
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="body"
                label="Body"
                type="text"
                value={form.body}
                onChange={handleChange('body')}
                margin="normal"
                multiline
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Labels
              </Typography>
              <Typography variant="body1" gutterBottom>
                Labels contain conditions or rules that control when certain content should display
                to which users.
              </Typography>
              <Select
                label="Select labels"
                id="labels"
                onChange={handleChange('labels')}
                value={form.labels}
                options={labelOptions}
                multiple
                fullWidth
              ></Select>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Facts
              </Typography>
              <Typography variant="body1" gutterBottom>
                Add all facts to be used in the calculated parameter values.
              </Typography>
              <Autocomplete
                label="facts"
                placeholder="Search for facts"
                suggestions={factOptions}
                initialValue={form.facts}
                onChange={handleChange('facts')}
                margin="normal"
                multiple
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Parameters
              </Typography>
              <Typography variant="body1" gutterBottom>
                {
                  'Parameters will auto-fill; however, you must add formatting instructions & remove '
                }
                {'unneeded items manually.'}
              </Typography>
              <ParamsField handleChange={handleChange('params')} initialValue={form.params} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Tests for params
              </Typography>
              <TestParams
                {...{
                  classes,
                  onChange: setParams,
                  ...(form.tests && { initialValue: form.tests.params }),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <BlocklyConditionsAndTests
                content={form}
                handleChange={handleConditions}
                blocklyHandleChange={handleBlocklyCondition}
                facts={facts.facts}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TenantsField
                handleTenantCheck={handleTenantCheck}
                initialValue={form.excludeTenants}
                tenantList={tenants}
                form={form}
                setForm={setForm}
              />
            </Grid>
          </Grid>
          <section className={classes.saveButtonContainer}>
            <Button color="primary" variant="contained" type="submit" size="medium">
              Save
            </Button>
          </section>
        </Form>
      </Paper>
    </Container>
  );
};

export default ObservationForm;
