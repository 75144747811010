import { push, goBack } from 'connected-react-router';

import * as service from 'services/programs';
import * as data from 'data/programs';

const postUpdateProgram = (program) => ({
  type: data.TYPE_UPDATE_PROGRAM,
  program,
});

const postUpdateProgramSuccess = (program, json) => ({
  type: data.TYPE_UPDATE_PROGRAM_SUCCESS,
  program: program,
  tenants_conflicts: json.tenants_conflicts,
});

const postUpdateProgramError = (program, error) => ({
  type: data.TYPE_UPDATE_PROGRAM_ERROR,
  error,
});

export const resetProgram = () => ({
  type: data.TYPE_RESET_PROGRAM,
});

const postCreateProgram = (program) => ({
  type: data.TYPE_CREATE_PROGRAM,
  program,
});

const postCreateProgramSuccess = (program, json) => {
  return {
    type: data.TYPE_CREATE_PROGRAM_SUCCESS,
    program: { ...program, id: 1 },
  };
};

const postCreateProgramError = (program, error) => ({
  type: data.TYPE_CREATE_PROGRAM_ERROR,
  error,
  program,
});

const receiveProgram = (json) => {
  return {
    type: data.TYPE_RECEIVE_PROGRAM,
    program: json.program,
  };
};

const requestProgram = () => ({
  type: data.TYPE_REQUEST_PROGRAM,
});

const receiveProgramError = (error) => ({
  type: data.TYPE_RECEIVE_PROGRAM_ERROR,
  error: error,
});

export const fetchProgram = (id) => {
  return (dispatch) => {
    dispatch(requestProgram());
    return service
      .fetchProgram(id)
      .then((response) => dispatch(receiveProgram(response)))
      .catch((error) => dispatch(receiveProgramError(error)));
  };
};

export const updateProgram = (program, cancel) => {
  if (cancel)
    return (dispatch) => {
      dispatch(goBack());
    };
  return (dispatch) => {
    dispatch(postUpdateProgram(program));
    return service
      .updateProgram(program)
      .then((response) => {
        dispatch(postUpdateProgramSuccess(program, response));
      })
      .catch((error) => dispatch(postUpdateProgramError(program, error)));
  };
};

export const createProgram = (program, cancel) => {
  if (cancel)
    return (dispatch) => {
      dispatch(goBack());
    };
  return (dispatch) => {
    dispatch(postCreateProgram(program));
    return service
      .createProgram(program)
      .then((response) => {
        dispatch(postCreateProgramSuccess(program, response));
        dispatch(push(`${response.program.id}/edit`));
      })
      .catch((error) => dispatch(postCreateProgramError(program, error)));
  };
};
