import React from 'react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, EditOutlined as EditIcon } from '@material-ui/icons';

import useStyles from './Cluster.styles';

const Cluster = ({ actions, name, labels }) => {
  const classes = useStyles();

  const renderLabel = (label, index) => {
    const insightPlural = label.insights === 1 ? 'insight' : 'insights';
    const pledgePlural = label.pledges?.length === 1 ? 'pledge' : 'pledges';
    const labelValue = [];
    labelValue.push(
      <ListItem key={index}>
        <ListItemText
          primary={
            <>
              <Typography display="inline" variant="body2">
                {label.name}
              </Typography>
              <Typography className={classes.labelCaption} variant="caption" color="textSecondary">
                {`${label.insights} ${insightPlural} and ${label.pledges?.length} ${pledgePlural} with this label.`}
              </Typography>
            </>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => actions.push(`/labels/${label.id}/edit`)}
            edge="end"
            size="small"
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );

    return labelValue;
  };

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={classes.titleContainer}>
          <Typography variant="body1">{name}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <List className={classes.list}>{labels.map(renderLabel)}</List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Cluster;
