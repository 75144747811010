import React, { useState } from 'react';
import classNames from 'classnames';
import {
  Avatar,
  Button,
  Typography,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Box
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import mealImage from 'assets/imgs/categories/meal.png';
import sleepImage from 'assets/imgs/categories/sleep.png';
import moveImage from 'assets/imgs/categories/move.png';
import { highlighter } from '../../../utils/columns';

import useStyles from './Pledge.styles';

const PLEDGES_CATEGORY_IMAGES = {
  1: mealImage,
  2: sleepImage,
  3: moveImage
};

const Pledge = ({ actions, pledge, searchText }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const renderField = (field, value) => {
    if (!value) return null;

    return (
      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.fieldName} variant="body1">{`${field}:`}</Typography>
        <Typography variant="body1">{value}</Typography>
      </Box>
    );
  };

  return (
    <Card className={classes.card}>
      <div className={classes.header}>
        <Avatar className={classes.avatar} src={PLEDGES_CATEGORY_IMAGES[pledge.category]} />
        <div className={classes.textContainer}>
          <p>{highlighter(searchText, pledge.name)}</p>
          <Typography className={classes.text} variant="body2" color="textSecondary">{`Priority: ${pledge.priority}`}</Typography>
        </div>
        <div>
          <IconButton onClick={() => setExpanded(!expanded)}>
            <ExpandMoreIcon className={classNames({ [classes.cardExpanded]: expanded })} />
          </IconButton>
        </div>
      </div>
      <Collapse in={expanded} timeout="auto">
        <CardContent className={classes.cardContainer}>
          {renderField('Type', pledge.type)}
          {renderField('Cooldown', pledge.cooldown)}
          <Typography variant="body1">{highlighter(searchText, pledge.body)}</Typography>
          <Typography variant="body2">{`Goal: ${pledge.goal}`}</Typography>
          <Button className={classes.cardActions} size="small" onClick={() => actions.push(`/pledges/${pledge.id}/edit`)}>Edit</Button>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default Pledge;
