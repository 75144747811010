import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function PopUp({ handlePopUpClose, updateModuleRules, Container, refId, id, form, itemSelected }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      handlePopUpClose();
    }, 500);
  };
  const handleUpdateModuleRules = (form, refId) => {
    updateModuleRules(form, refId);
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Container
            updateModuleRules={handleUpdateModuleRules}
            onClose={handleClose}
            refId={refId}
            id={id}
            currentForm={form}
            itemSelected={itemSelected}
          />
          
        </Fade>
      </Modal>
    </div>
  );
}
