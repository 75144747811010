import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  table: {
    minWidth: 650,
  },
  button: {
    margin: '1rem auto'
  }
});
