export const formatString = (str) => {
  return str.replace(/(\r\n|\n|\r)/gm, " ");
};

export const getOp = {
  '<': function (a, b) { return a < b; },
  '>': function (a, b) { return a > b; },
  '>=': function (a, b) { return a >= b; },
  '<=': function (a, b) { return a <= b; },
  '!=': function (a, b) { return a !== b; },
  '=': function (a, b) { return a === b; },
};