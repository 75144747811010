import { authorizedAxios } from 'config/axios';

const FACTUARY_URL = '/factuary/api/v1';

/**
 * Service that will request labels from the factuary api.
 *
 * The labels endpoint allows you to filter the fields that are included in
 * the response. This is beneficial so that we aren't loading an obscene amount
 * of data on pages that do not require it.
 *
 * @param {array} fields - white list fields to include. Will include all if null.
 * @param {array} facts - filter labels to those that use all of these facts
 * @param {string} query - string to match in labels
 * @return {Promise}
 */
export const fetchLabels = (fields, facts, query) => {
  const params = new URLSearchParams();

  if (fields) params.append('fields', fields.join(','));
  if (facts) params.append('facts', facts.join(','));
  if (query) params.append('q', query);

  return authorizedAxios.get(`${FACTUARY_URL}/labels?${params}`);
};

/**
 * Service that will request a single label from the factuary api
 *
 * @param {number} id - the id of the label you wish to load
 * @return {Promise}
 */
export const fetchLabel = id => {
  return authorizedAxios.get(`${FACTUARY_URL}/labels/${id}`);
};

/**
 * Persist an existing label to update it's data
 */
export const updateLabel = (labelId, label) => {
  return authorizedAxios.put(`${FACTUARY_URL}/labels/${labelId}`, label);
};

/**
 * Persist a new label.
 *
 * @param {object} label - the new label data.
 * @return {Promise}
 */
export function createLabel(label) {
  return authorizedAxios.post(`${FACTUARY_URL}/labels`, label);
}
