import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import useStyles from './CustomSelect.styles';

const CustomSelect = ({ multiple, options, setSelection, type }) => {
  const classes = useStyles();
  const [checkboxes, setCheckboxes] = useState(options);

  useEffect(() => {
    // eslint-disable-next-line
    const checkboxesEntries = Object.entries(checkboxes);
    let stringInInput = [];
    checkboxesEntries.forEach((statusOption) => {
      if (statusOption[1].status) {
        stringInInput.push(statusOption[0]);
      }
    });
  }, [checkboxes]);

  const handleCheckboxChange = (option, element) => {
    let checkboxesState = checkboxes;

    if (element === 'Type') {
      let notSelectedCheckboxes = Object.keys(checkboxes);
      const index = notSelectedCheckboxes.indexOf(option);
      if (index > -1) {
        notSelectedCheckboxes.splice(index, 1);
        checkboxesState[notSelectedCheckboxes[0]].status = false;
        checkboxesState[notSelectedCheckboxes[1]].status = false;
      }
    }

    checkboxesState[option].status = !checkboxesState[option].status;

    setCheckboxes({ ...checkboxesState });
    if (element === 'Type') {
      setSelection({ type: 'Type', checkboxesState });
    } else {
      setSelection({ type: 'Status', checkboxesState });
    }
  };
  if (multiple) {
    let statusSelected = [];
    const statusArray = Object.keys(options);
    statusArray.forEach((status) => {
      if (checkboxes[status].status) {
        statusSelected.push(status);
      }
    });
    return (
      <div className={classes.container}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="button" display="block" align="center">
            Status
          </Typography>
          <FormControl fullWidth>
            <FormGroup row={true}>
              {Object.keys(options).map((option, index) => {
                if (type.Survey.status) {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={checkboxes[option].status}
                          onChange={() => handleCheckboxChange(option, 'Status')}
                        />
                      }
                      label={option}
                    />
                  );
                } else if (option !== "Review" && option !== "Prerelease") {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={checkboxes[option].status}
                          onChange={() => handleCheckboxChange(option, 'Status')}
                        />
                      }
                      label={option}
                    />
                  );
                }
                return null;

              })}
            </FormGroup>
          </FormControl>
        </Box>
        <Divider orientation="vertical" className={classes.divider} />
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="button" display="block" align="center">
          Type
        </Typography>
        <FormControl fullWidth>
          <RadioGroup row={true}>
            {Object.keys(options).map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Radio
                    checked={checkboxes[option].status}
                    onChange={() => handleCheckboxChange(option, 'Type')}
                  />
                }
                label={option}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Divider orientation="vertical" className={classes.divider} />
    </div>
  );
};

export default CustomSelect;
