import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

import PledgeForm from 'components/views/pledges/form';

import useStyles from './CreatePledge.styles';
import { tenantHandler } from 'services/shared/handlers';

const CreatePledge = ({ actions, facts, pledge, labels, tenants }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    name: '',
    priority: '0',
    goal: '',
    category: '1',
    title: '',
    labels: [],
    abTestNames: [],
    endTime: '1439',
    startTime: '0',
    availableLangs: [],
    enableLangs: [],
    lang: 'en',
    appId: '',
    multiDay: false,
    isLadder: false,
    rhsParams: [],
    extraParams: {},
    rhsLogic: [],
    conditions: [],
    status: '0',
    tests: {
      conditions: [],
      params: []
    },
    description: '',
    type: '0',
    notes: '',
    authorId: '0',
    headerText: '',
    bodyText: '',
    declineText: '',
    acceptText: '',
    tenants: [],
    excludeTenants: []
  });

  useEffect(() => {
    actions.onMount();
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tenants.tenants?.length) {
      setForm({ ...form, tenants: tenants.tenants.map(tenant => tenant.id) });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants]);

  const handleChange = name => ({ target: { value } }) => {
    setForm({
      ...form,
      [name]: value
    });
  };

  const updatedLabels = () => {
    const newLabels = form.labels.map(index => {
      const { name, flags, id, type } = labels.labels[index];
      return { name, flags, id, type };
    });
    return newLabels;
  };

  const handleCheck = name => ({ target: { checked } }) => {
    setForm({
      ...form,
      [name]: checked
    });
  };

  const handleTenantCheck = (tenantIndex) => ({ target }) => {
    tenantHandler(tenantIndex, target, form, tenants, setForm);
  };

  const handleSubmit = () => {
    if (validateFields())
      actions.createPledge({ ...form, labels: updatedLabels() }).catch(console.log);
  };

  const validateFields = () => {
    return !(!form.multiDay && form.ttl > 1) && !(form.priority > 200);
  };

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create Pledge</Typography>
      <PledgeForm
        facts={facts}
        tenants={tenants}
        form={form}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleTenantCheck={handleTenantCheck}
        labels={labels}
        onSubmit={handleSubmit}
        setForm={setForm}
      />
    </div>
  );
};

export default CreatePledge;
