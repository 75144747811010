import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addColumn, fetchColumnData, loadColumns, persistColumns, removeColumn, updateFilters } from 'actions/columns';
import Column from 'components/views/columns/column-container';
import AddColumnDialog from 'components/views/columns/add-column-dialog';
import { bindActionCreators } from 'redux';

import { IconButton, Button as MUIButton, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import useStyles from './Columns.styles';

const Columns = ({ actions, name, columns, service, menuOptions, cardBuilder }) => {
  const [openAddColumnDialog, setOpenAddColumnDialog] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    actions.loadColumns({ name });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    actions.persistColumns({ name });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const handleAddColumn = columnName => {
    actions.addColumn(name, columnName);
  };

  const ColumnsContainer = () => {
    const hasColumns = columns != null;
    if (hasColumns) {
      return (
        <>
          {columns.map(column => (
            <Column
              key={column.id}
              name={name}
              column={column}
              actions={actions}
              service={service}
              menuOptions={menuOptions}
              cardBuilder={cardBuilder}
            />
          ))}
          <div className={classes.addColumnButton}>
            <IconButton onClick={() => setOpenAddColumnDialog(true)}>
              <AddIcon />
            </IconButton>
          </div>
        </>
      );
    } else {
      return (
        <div className={classes.noContentContainer}>
          <Typography variant="h4" gutterBottom>
            There's nothing to see here.
          </Typography>
          <Typography variant="body1" gutterBottom>
            You have not added a column of {name} yet, click below to add one.
          </Typography>
          <MUIButton
            className={classes.noContentButton}
            onClick={() => setOpenAddColumnDialog(true)}
            color="secondary"
          >
            Add new column
          </MUIButton>
        </div>
      )
    }
  };

  return (
    <>
      {ColumnsContainer()}
      <AddColumnDialog
        open={openAddColumnDialog}
        onClose={() => setOpenAddColumnDialog(false)}
        onSubmit={handleAddColumn}
      />
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  columns: state.columns[ownProps.name],
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        loadColumns,
        fetchColumnData,
        addColumn,
        updateFilters,
        removeColumn,
        persistColumns
      },
      dispatch
    ),
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Columns);