import { TENANT_INCLUDE } from 'data/tenants';

export const tenantHandler = (tenantIndex, target, form, tenants, setForm) => {
  let tenantList = form.tenants || [];
  let excludeTenantsList = form.excludeTenants || [];
  const selectedId = tenants.tenants[tenantIndex].id;
  if (target.value === TENANT_INCLUDE) {
    excludeTenantsList = excludeTenantsList.filter(tenant => tenant !== selectedId);
    if (!tenantList.includes(selectedId)) {
      tenantList.push(selectedId);
    }
  } else {
    if (!excludeTenantsList.includes(selectedId)) {
      excludeTenantsList.push(selectedId);
    }
    tenantList = tenantList.filter(tenant => tenant !== selectedId);
  }
  setForm({
    ...form,
    tenants: tenantList,
    excludeTenants: excludeTenantsList
  });
};