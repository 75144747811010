import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Typography } from '@material-ui/core';

import Button from 'components/commons/button';
import Form from 'components/commons/form';

import useStyles from './Login.styles';

const Login = ({ actions, isAuthed }) => {
  const classes = useStyles();

  useEffect(() => {
    const prevPage = localStorage.getItem('prevPage');
    if (!isAuthed && prevPage !== '/' && prevPage !== '/login') {
      window.location.href = window.location.origin + '/login';
    }
  }, [isAuthed]);
  const handleSubmit = () => {
    actions.login();
  };

  return (
    <Container className={classes.container} maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <Typography variant="body1" gutterBottom>
        If one had no selfish motives, but only the supreme virtues, there would be no self&hellip;
        Regardless, we're going to ask that you identify yourself with your jawbone.com credentials.
      </Typography>
      <Form onSubmit={handleSubmit}>
        <Button color="primary" variant="contained" type="submit" size="medium">
          Login with Azure
        </Button>
      </Form>      
    </Container>
  );
};

Login.propTypes = {
  user: PropTypes.object,
  actions: PropTypes.object.isRequired,
};

export default Login;
