import React, { useState, useEffect, useRef } from 'react';
import './BlocklyComponent.css';

import Blockly from 'blockly/core';
import locale from 'blockly/msg/en';
import 'blockly/blocks';

Blockly.setLocale(locale);

const BlocklyComponent = ({ setWorkSpace, workspace, handleChange, ...props }) => {
  const blocklyDiv = useRef({});
  const toolbox = useRef({});
  const [primaryWorkspace, setPrimaryWorkspace] = useState(null);
  useEffect(() => {
    const { initialXml, ...rest } = props;
    if (!primaryWorkspace)
      setPrimaryWorkspace(Blockly.inject(
        blocklyDiv.current,
        {
          toolbox: toolbox.current,
          ...rest
        },
      ));
    if (primaryWorkspace) {
      workspace.XML = Blockly.Xml.workspaceToDom(primaryWorkspace);
      if (workspace.update === 0)
        Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(initialXml), primaryWorkspace);
      setWorkSpace({ ...workspace, workspace: primaryWorkspace, XML: Blockly.Xml.workspaceToDom(primaryWorkspace) });
      handleChange(null, null, workspace.XML);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryWorkspace, workspace.update]);

  return <>
    <div ref={blocklyDiv} id="blocklyDiv" />
    <xml xmlns="https://developers.google.com/blockly/xml" is="blockly" style={{ display: 'none' }} ref={toolbox}>
      {props.children}
    </xml>
  </>;

};

export default BlocklyComponent;
