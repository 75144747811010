import React, { useState, forwardRef } from 'react';

import RulesForm from 'components/views/programs/modules/rules/form';
import useStyles from './EditRules.styles';

const EditRules = forwardRef(
  ({ onClose, updateModuleRules, refId, id, currentForm , itemSelected}, ref) => {
    const classes = useStyles();
    const [form, setForm] = useState({});

    const handleSubmit = () => {
      updateModuleRules(form, refId);
      onClose();
    };

    const handleCancel = () => {
      onClose();
    };

    const handlers = {
      handleSubmit,
      handleCancel,
    };

    return (
      <div className={classes.pageContainer}>
          <RulesForm
            {...{
              handlers,
              setForm,
              currentForm,
              refId,
              id, 
              itemSelected
            }}
          />
      </div>
    );
  }
);

export default EditRules;
