import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  column: {
    whiteSpace: 'normal',
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    display: 'inline-block',
    width: '500px',
    height: '100%',
    overflowY: 'scroll',
    position: 'sticky'
  },
  loadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem'
  },
  headerContainer: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2),
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  filterChip: {
    margin: theme.spacing(0.5)
  },
  bodyContainer: {
    padding: theme.spacing(0, 2, 2, 2)
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  applyBtn: {
    width: '1rem'
  }
}));
