import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Typography, CircularProgress } from '@material-ui/core';

import TestResults from 'components/commons/test-results';
import PledgeForm from 'components/views/pledges/form';

import useStyles from './EditPledge.styles';
import { tenantHandler } from 'services/shared/handlers';

const EditPledge = ({ actions, facts, pledge, pledgeId, labels, tenants }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});
  const { testPassed, testResults } = pledge;

  useEffect(() => {
    actions.onMount();
    actions.fetchPledge(pledgeId);
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(pledge.pledge)) {
      setForm({ ...pledge.pledge, labels: pledge.pledge.labels?.map(label => label.id) });
    };
  }, [pledge]);

  const handleChange = name => ({ target: { value } }) => {
    setForm({
      ...form,
      [name]: value
    });
  };

  const updatedLabels = () => {
    const newLabels = form.labels.map(index => {
      const { name, flags, id, type } = labels.labels[index];
      return { name, flags, id, type };
    });
    return newLabels;
  };

  const handleCheck = name => ({ target: { checked } }) => {
    setForm({
      ...form,
      [name]: checked
    });
  };

  const handleTenantCheck = (tenantIndex) => ({ target }) => {
    tenantHandler(tenantIndex, target, form, tenants, setForm);
  };

  const handleSubmit = () => {
    if (validateFields())
      actions.updatePledge({ ...form, labels: updatedLabels() }).catch(console.log);
  };

  const handleFileDownload = (language) => {
    return actions.getLanguageFile(form.id, language);
  };

  const handleTemplateFileDownload = () => {
    return actions.getLanguageFileTemplate(form.id);
  };

  const handleFileUpload = (file, language) => {
    let languageFile = new FormData();
    languageFile.append('file', file, file.name);
    actions.saveLanguageFile(languageFile, form.id, language);
  };

  const handleStatusChange = (availableLanguages, disabledLanguages) => {
    setForm({
      ...form,
      availableLangs: [...availableLanguages],
      enableLangs: [...disabledLanguages]
    });
  };

  const validateFields = () => {
    return !(!form.multiDay && form.ttl > 1) && !(form.priority > 200);
  };

  return (
    <div className={classes.pageContainer}>
      <div className={classes.headerContainer}>
        <Typography variant="h5">Edit Pledge</Typography>
        <div className={classes.testContainer}>
          <TestResults testPassed={testPassed} testResults={testResults} />
        </div>
      </div>
      {isEmpty(form) || pledge.isFetching ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <PledgeForm
          facts={facts}
          tenants={tenants}
          form={form}
          setForm={setForm}
          handleChange={handleChange}
          handleCheck={handleCheck}
          handleTenantCheck={handleTenantCheck}
          handleFileDownload={handleFileDownload}
          handleTemplateFileDownload={handleTemplateFileDownload}
          handleFileUpload={handleFileUpload}
          handleStatusChange={handleStatusChange}
          labels={labels}
          onSubmit={handleSubmit}
          nonEditableName={true}
          testResults={testResults}
        />
      )}
    </div>
  );
};

export default EditPledge;
