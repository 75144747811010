import React from 'react';
import SurveyQuestions from 'components/views/surveys/questions';

import { Box } from '@material-ui/core';

const QuetionsForm = ({ form, handleChange, isSingleType }) => {
  return (
    <Box pt={4} pb={4}>
      <SurveyQuestions
        displayAddQuestion={!isSingleType}
        initialValue={form.questions}
        surveyName={form.name}
        surveyId={form.id}
        onChange={handleChange('questions')}
      />
    </Box>
  );
};

export default QuetionsForm;
