import React, { useState, useEffect } from 'react';
import { isEmpty, update, constant } from 'lodash';
import { Typography, CircularProgress } from '@material-ui/core';

import TestResults from 'components/commons/test-results';
import ObservationForm from 'components/views/observations/form';
import useStyles from './EditObservation.styles';

const EditObservation = ({ actions, facts, insight, insightId, labels, tenants }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});
  useEffect(() => {
    actions.onMount();
    actions.fetchObservation(insightId);
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(insight.insight)) setForm(insight.insight);
  }, [insight]);

  const updatedLabels = () => {
    const newLabels = form.labels.map(index => {
      const { name, flags, id, type } = labels.labels[index];
      return { name, flags, id, type };
    });
    return newLabels;
  };

  const handleChange = name => e => setForm({ ...update(form, name, constant(e.target.value)) });

  const handleSubmit = () => actions.updateObservation({ ...form, labels: updatedLabels() });

  const { testPassed, testResults } = insight;

  const renderContent = () => {
    if (isEmpty(form) || insight.isFetching) {
      return (<div className={classes.loadingContainer}>
        <CircularProgress />
      </div>);
    }

    // if (insight.insight.type !== "2") {
    //   return (
    //     <h2>Content not available</h2>
    //   );
    // }

    return (
      <ObservationForm
        facts={facts}
        tenants={tenants.tenants}
        form={form}
        setForm={setForm}
        handleChange={handleChange}
        labels={labels}
        onSubmit={handleSubmit}
        nonEditableName={true}
      />
    );
  };

  return (
    <div className={classes.pageContainer}>
      <div className={classes.headerContainer}>
        <Typography variant="h5" display="inline">
          Edit Observation
        </Typography>
        <div className={classes.testContainer}>
          <TestResults testPassed={testPassed} testResults={testResults} />
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

export default EditObservation;
