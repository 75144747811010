import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from 'react-autocomplete';
import useStyles from './ListItemSelector.styles';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';



const ListItemSelector = ({ value, options, onSearchChange, onValueSelect, onRemove, onEdit, multiple = false,  disabledInput = false, tooltip = '', errorMessage = '', infoMessage = '', typeValue ='' }) => {
  const classes = useStyles();
  const [currentValue, setCurrentValue] = useState();
  const editEl = useRef(null);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const suggestions = options.filter(option => {
    if (option?.label) {
      return option.label.toLowerCase()
        .includes(currentValue?.toLowerCase());
    } else {
      return false;
    }
  });

  const Input = ({ placeholder = 'Input search query', ...rest }) => {
    return (
      <div className={classes.inputFieldContainer}>
        {tooltip ? <Tooltip title={tooltip} placement="bottom-start">
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip> : ''}
        <div className={classes.inputBox}>
          <input className={classes.inputField} placeholder={placeholder} {...rest} disabled={disabledInput} required />
          <small className={classes.inputError}>{errorMessage}</small>
          <small className={classes.inputInfo}>{infoMessage}</small>
        </div>
      </div>
    )
  }
  let viewTypeToolTip, customToolTip;
  if(window.location.pathname.includes('programs')){
    customToolTip = 
    <Tooltip title="Edit Module">
      <span>
        <IconButton ref={editEl} onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}
        >
          <EditIcon />
        </IconButton>
      </span>
    </Tooltip>;
  } else {
    viewTypeToolTip = 
     <Tooltip title={`View ${typeValue}`}>
    <span>
      <IconButton  ref={editEl} onClick={(e) => {
        e.stopPropagation();
        onEdit("liveView");
      }}>
      <VisibilityIcon />
      </IconButton>
    </span>
  </Tooltip>;
    customToolTip =      
      <Tooltip title={`Edit ${typeValue} rules`}>
      <span>
        <IconButton  ref={editEl} onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}>
        <EditIcon />
        </IconButton>
      </span>
    </Tooltip>
  }
  
  return (
    <div className={classes.itemContainer}>
      <div className={classes.autocompleteContainer}
        onClick={(e) => e.stopPropagation()}>
        <Autocomplete
          wrapperStyle={{ flex: '1' }}
          getItemValue={item => item.label}
          items={suggestions}
          renderInput={Input}
          inputProps={{ placeholder: 'Input a search term' }}
          renderMenu={(children) => {
            return options && options.length
              ? (
                <div className={classes.inputSuggestions}>
                  {children}
                </div>
              )
              : <></>;
          }}
          renderItem={
            (item, highlighted) => {
              return (
                <div
                  className={classes.inputSuggestionsItem}
                  key={item.value}
                  style={{ backgroundColor: highlighted ? '#eee' : 'transparent' }}>
                  {item.label}
                </div>)
            }}
          value={currentValue}
          onSelect={(val) => {
            setCurrentValue(val);
            onValueSelect(val);
          }}
          onChange={(e) => {
            setCurrentValue(e.target.value);
            onSearchChange(e);
          }}
        />
      </div>
      <div className={classes.actions}>
        {viewTypeToolTip}
      {customToolTip}
        {multiple
          ? (
            <IconButton onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}>
              <RemoveCircleOutlineIcon />
            </IconButton>)
          : ''}
      </div>
    </div>
  );
}

export default ListItemSelector;