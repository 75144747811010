import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  tracksContainer: {
    height: '100%',
    width: '100%',
    overflow: 'scroll',
  },
  scale: {
    transform: 'scale(0.8)',
  },
}));
