import React, { useEffect } from 'react';
import { Menu, MenuItem } from '@material-ui/core';

import Sidebar from 'components/templates/sidebar';
import Columns from '../columns';
import InsightCard from 'components/views/insights/insight-card';
import ObservationCard from 'components/views/observations/observation-card';

import useStyles from './Insights.styles';

const menuOptions = [
  {
    text: 'Type',
    value: 'type',
    children: [
      {
        text: 'Short insight',
        value: 'short'
      },
      {
        text: 'Long insight',
        value: 'long'
      },
      {
        text: 'Observation',
        value: 'observation'
      }
    ]
  },
  {
    text: 'Status',
    value: 'status',
    children: [
      {
        text: 'Draft',
        value: 'draft'
      },
      {
        text: 'Live',
        value: 'live'
      },
      {
        text: 'Deactivated',
        value: 'deactivated'
      }
    ]
  }
];

const Insights = ({ actions }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const cardBuilder = (insight, index, searchedText) => {
    if (insight.type === 'observation')
      return <ObservationCard key={index} {...{ actions, insight, index, searchedText }} />;
    return <InsightCard key={index} {...{ actions, insight, index, searchedText }} />;
  };

  useEffect(() => {
    actions.onMount();
    return () => {
      actions.onUnmount();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateClick = event => setAnchorEl(event.currentTarget);

  const handleCloseCreate = () => setAnchorEl(null);

  return (
    <div className={classes.pageContainer}>
      <Sidebar
        title="Insights"
        mainButtonAction={handleCreateClick}
        mainButtonText="Create insight"
      >
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseCreate}
        >
          <MenuItem onClick={() => actions.push('/insights/create')}>Long/Short Insight</MenuItem>
          <MenuItem onClick={() => actions.push('/observations/create')}>Observation</MenuItem>
        </Menu>
      </Sidebar>
      <div className={classes.insightsContainer}>
        <Columns name="insights" service={actions.fetchInsights} menuOptions={menuOptions} cardBuilder={cardBuilder} />
      </div>
    </div>
  );
};

export default Insights;
