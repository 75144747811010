import {
  TYPE_LOAD_COLUMNS,
  TYPE_REQUEST_COLUMN_DATA,
  TYPE_RECEIVE_COLUMN_DATA,
  TYPE_RECEIVE_COLUMN_DATA_ERROR,
  TYPE_ADD_COLUMN,
  TYPE_UPDATE_COLUMN_FILTERS,
  TYPE_REMOVE_COLUMN,
  TYPE_PERSIST_COLUMNS
} from 'data/columns';

const requestColumnData = (name, columnId) => ({
  name,
  type: TYPE_REQUEST_COLUMN_DATA,
  columnId: columnId,
  isFetching: true
});

const receiveColumnData = (name, columnId, response) => {
  return ({
    name,
    type: TYPE_RECEIVE_COLUMN_DATA,
    isFetching: false,
    columnId: columnId,
    data: response[name],
    currentPage: response.currentPage || 1,
    nextPage: response.nextPage || false,
  })
};

const receiveColumnDataError = (name, columnId, errorText) => ({
  name,
  type: TYPE_RECEIVE_COLUMN_DATA_ERROR,
  columnId: columnId,
  error: errorText,
  isFetching: false
});

export const addColumn = (name, columnName) => ({
  type: TYPE_ADD_COLUMN,
  name,
  columnName
});

export const removeColumn = (name, columnId) => ({
  name,
  type: TYPE_REMOVE_COLUMN,
  columnId
});

export const updateFilters = ({ name, columnId, filters }) => ({
  name,
  type: TYPE_UPDATE_COLUMN_FILTERS,
  columnId,
  filters
});

export const fetchColumnData = ({ name, columnId, filters, page, fetchData }) => dispatch => {
  dispatch(requestColumnData(name, columnId));
  return fetchData(filters, page)
    .then(data => dispatch(receiveColumnData(name, columnId, data)))
    .catch(error => dispatch(receiveColumnDataError(name, columnId, error)));
};

export const loadColumns = ({ name }) => ({ type: TYPE_LOAD_COLUMNS, name });

export const persistColumns = ({ name }) => ({ type: TYPE_PERSIST_COLUMNS, name });