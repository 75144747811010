import React, { useEffect, useState }  from 'react'
import { RoundedButton } from 'components/commons';

import SportsOutlinedIcon from '@material-ui/icons/SportsOutlined';
import { CircularProgress, Box } from '@material-ui/core';

export const PledgeCard = ({
  title = '',
  body = '',
  acceptText = '',
  declineText = '',
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  if(loading){
    return <Box sx={{ display: 'flex', position: 'absolute', top: '50%', left : "47%" }}>
    <CircularProgress />
  </Box>
  }
  const selectedDevice = document.getElementsByClassName("_1HaoU")[0].innerHTML;
  const dynamicClass = (selectedDevice === "Note 8" || selectedDevice === "Nexus 5" ) ? "text-cardTitle font-black font-gotham" : "text-cardTitle font-black font-gotham line-clamp-3";
   
  return (
    <div className="flex flex-col rounded-2xl shadow-lg mx-4 p-4 bg-white">
      <div className="flex flex-row items-center">
        <div className="mr-2">
          <SportsOutlinedIcon
            className="text-mostly-desaturated-dark-blue"
            fontSize="large"
          ></SportsOutlinedIcon>
        </div>
        <div className="flex flex-col">
          <div className="font-gotham">Smart Coach</div>
          <div className="font-bold font-gotham">Today I Will</div>
        </div>
      </div>
      <div className="mt-6">
        <div className={dynamicClass}>{title}</div>
      </div>
      <div className="mt-3 text-sm font-gotham">{body}</div>
      <div className="mt-14 flex flex-row justify-between">
        <RoundedButton text={declineText} variant />
        <RoundedButton text={acceptText} variant />
      </div>
    </div>
  );
};

export default PledgeCard;