const evalCustomFunction = (expression) => {
    //eslint-disable-next-line 
    return Function('"use strict";return (' + expression + ')')();
};

const validateTest = (inputCode, facts, value) => {
    try {
        let factsObject = {};
        facts.forEach((fact) => {
            if (fact[0] === "user_cohort") {
                factsObject[fact[0]] = JSON.stringify(fact[1].split(","));
            } else if (fact[1] === "" || fact[1] == null) {
                factsObject[fact[0]] = "missing-value";
            } else if (isNaN(fact[1])) {
                factsObject[fact[0]] = `"${fact[1]}"`;
            } else {
                factsObject[fact[0]] = Number(fact[1]);
            }
        });
        const arrayOfFactProps = Object.keys(factsObject);
        let outputCode = inputCode;
        arrayOfFactProps.forEach((factName) => {
            const value = factsObject[factName];
            outputCode = outputCode.replaceAll(factName, value);
        });
        return evalCustomFunction(outputCode) === value;
    } catch (error) {
        return false;
    }
};
export default validateTest;