import React from 'react';
import {
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';

import { sortByLastModifiedDesc } from 'utils/entity';

import useStyles from './Column.styles';

const Body = ({ error, isFetching, data, highlightedText, cardBuilder, nextPage, handleLoadMore }) => {
  const classes = useStyles();

  if (error)
    return (
      <>
        <Typography color="error" variant="body1">
          Woops! It looks like the server did not like our request. Here is what was returned:
        </Typography>
        <Typography color="error" variant="overline">
          {error}
        </Typography>
      </>
    );

  if (isEmpty(data) && isFetching)
    if (isFetching)
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      );
  const body = data?.sort(sortByLastModifiedDesc).map((item, index) => cardBuilder(item, index, highlightedText)) || <h1>Not data found</h1>;

  if (body?.length === 0) {
    return (
      <Typography variant="body1">
        While fundamentally good, there are no results for your chosen filters. Please alter your
        filters to try again.
      </Typography>
    );
  }

  if (nextPage) {
    body.push(
      <div key="load-more" className={classes.loadMoreContainer}>
        <Button color="secondary" onClick={handleLoadMore}>
          Load More
          </Button>
      </div>
    );
  }

  return body;
}

export default Body;