import { authorizedAxios } from 'config/axios';
import ReportFactory from 'factories/reports';

const FACTUARY_URL = '/factuary/api/v2';

export const fetchReports = (contentType, status, page, perPage, availableTenants) => {
  return authorizedAxios.get(`${FACTUARY_URL}/content/filter/?content_type=${contentType}&status=${status}&page=${page}&per_page=${perPage}`).then((response) => {
    return {
      reports: response.data.map(item => ReportFactory.toModel(item, availableTenants)),
      meta: response.meta
    };
  });
};
