import React, { useEffect, useState } from 'react';
import { DateInput, KeypadInput, PickerInput } from 'components/commons';

import CloseIcon from '@material-ui/icons/Close';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';

export const CustomAnswerCard = ({ questions, cardPosition, handleShow }) => {
  const [questionPosition, setQuestionPosition] = useState(cardPosition);

  useEffect(() => {
    setQuestionPosition(cardPosition);
  }, [cardPosition]);

  const switchInputType = (option) => {
      switch (option.inputType) {
          case 'keypad':
              return <KeypadInput/>;
          case 'date':
              return <DateInput />;
          case 'picker':
              return <PickerInput cardPosition={cardPosition} />;
          default:
              break;
      }
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center mt-4">
        <div className="flex flex-row">
          <div className="mr-2">
            <EventNoteRoundedIcon className="text-soft-red" fontSize="large" />
          </div>
          <div className="flex flex-col">
            <div className="font-gotham">Smart Coach</div>
            <div className="font-bold font-gotham">
              Question: {questionPosition + 1}/{questions?.length}
            </div>
          </div>
        </div>
        <button onClick={!window.location.pathname.includes("question") ? handleShow : ()=>null}>
          <CloseIcon fontSize="large"></CloseIcon>
        </button>
      </div>
      <div className="text-cardTitle2 font-black font-gotham mt-6 whitespace-pre-line break-words mr-1">
        {questions[questionPosition].text}
      </div>
      <div className="flex flex-col mt-4">
        <div className="text-cardBody font-gotham">Select answer:</div>
        {questions[questionPosition].options.map((option, index) => {
          return <>{switchInputType(option)}</>;
        })}
      </div>
    </>
  );
};

export default CustomAnswerCard;
