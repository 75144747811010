import React, { useEffect, useState } from 'react';
import { LongInsightCard, RoundedButton, SimpleAnswerCard } from 'components/commons';
import CustomAnswerCard from 'components/commons/cards/custom-answer-card';
import { CircularProgress, Box } from '@material-ui/core';

export const LongCardLayout = ({ show, setShow, questions, title, body, questionId }) => {
  const [cardPosition, setCardPosition] = useState(-1);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    questions &&
      questions.map((question, index) => {
        if (question.id === questionId) {
          return setCardPosition(index);
        }
        return questions;
      });
  }, [questions, questionId]);

  const switchAnswerType = () => {
    if (questions[cardPosition]) {
      switch (Number(Object(questions[cardPosition])?.type)) {
        case -1:
          return (
            <LongInsightCard
              questions={questions}
              title={title}
              body={body}
              handleShow={handleShow}
            />
          );
        case 0:
          return (
            <SimpleAnswerCard
              questions={questions}
              title={title}
              body={body}
              cardPosition={cardPosition}
              handleShow={handleShow}
            />
          );
        case 1:
          return (
            <CustomAnswerCard
              questions={questions}
              title={title}
              body={body}
              cardPosition={cardPosition}
              handleShow={handleShow}
            />
          );
        default:
          return (
            <SimpleAnswerCard
              questions={questions}
              title={title}
              body={body}
              cardPosition={cardPosition}
              handleShow={handleShow}
            />
          );
      }
    } else {
      return (
        <LongInsightCard questions={questions} title={title} body={body} handleShow={handleShow} />
      );
    }
  };

  const handleCardPosition = () => {
    if (cardPosition < 0) {
      let position = cardPosition + 1;
      setCardPosition(position);
    }
  };

  const handleShow = () => {
    setCardPosition(-1);
    setShow();
  };
  if(loading){
    return <Box sx={{ display: 'flex', position: 'absolute', top: '50%', left : "47%" }}>
    <CircularProgress />
  </Box>
  }
  return (
    <>
      {show && (
        <div className="absolute flex flex-col justify-between bg-black w-full h-full mt-20 rounded-xl">
          <div className="flex flex-col justify-between bg-white mt-2 rounded-3xl items-center mx-3 px-6 h-full mb-16">
            {questions && <div className="flex flex-col ">{switchAnswerType()}</div>}
            <div className="mb-6 flex flex-row justify-center w-full">
              <RoundedButton
                text={cardPosition === -1 ? 'Begin' : 'Next'}
                variant={true}
                action={handleCardPosition}
                borderColor={cardPosition === 0 ? true : false}
              />
            </div>
          </div>

          <div className="flex flex-col justify-center items-center w-full">
            <div className="bg-very-dark-mostly-black-orange w-full h-18"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default LongCardLayout;
