import React from 'react'

export const PickerInput = ({ cardPosition }) => {
  return (
    <div>
      <select
        name="optios"
        id="pet-select"
        className="border-2 border-black rounded-xl bg-gray-500 h-11 pl-3 flex flex-row items-center font-gotham mt-4 bg-light-grayish-orange-2 w-full appearance-none"
      >
        <option value=""></option>
        <option value="goldfish">0</option>
      </select>
    </div>
  );
};

export default PickerInput;