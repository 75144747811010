import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';

import ProgramForm from 'components/views/programs/form';
import useStyles from './CreateProgram.styles';
import { tenantHandler } from 'services/shared/handlers';

const CreateProgram = ({ actions, tenants, facts }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    name: '',
    status: 'draft',
    conditions: [],
    tests: {
      conditions: [],
    },
    modules: [],
    edges: [],
    tenants: [],
    excludeTenants: [],
    cooldown: '0',
    ttl: '',
  });

  useEffect(() => {
    actions.onMount();
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tenants.tenants?.length) {
      setForm({ ...form, tenants: tenants.tenants.map(tenant => tenant.id) });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants]);

  const handleChange =
    (name) =>
      ({ target: { value } }) => {
        setForm({
          ...form,
          [name]: value,
        });
      };

  const handleTenantCheck =
    (tenantIndex) =>
      ({ target }) => {
        tenantHandler(tenantIndex, target, form, tenants, setForm);
      };

  const handleSubmit = (cancel = false) => {
    actions.createProgram(form, cancel);
  };

  const handlers = { handleChange, handleSubmit, handleTenantCheck };

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create Program</Typography>
      <ProgramForm
        {...{
          form,
          handlers,
          setForm,
          edit: false,
          tenants,
          facts
        }}
      />
    </div>
  );
};

export default CreateProgram;
