import { fetchModules } from 'actions/modules';
import { createProgram, resetProgram, updateProgram } from 'actions/program';
import { fetchPrograms } from 'actions/programs';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Programs } from 'views';

const mapStateToProps = state => ({
  programs: state.programs,
  modules: state.modules
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        fetchPrograms,
        createProgram,
        updateProgram,
        push,
      },
      dispatch
    ),
    onMountModules: () => {
      dispatch(fetchModules());
    },
    onUnmount: () => {
      dispatch(resetProgram());
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Programs);
