/**
 * Remove unpritable char generated by blockly
 * @param {Array.<string>}  data  Array of strings
 * @return  {Array.<string>}  result  Array of clean strings
 */
export const cleanConditions = (data) => {
  const result = data?.map((item) => {
    // Removing unprintable char. Please don't remove it
    return item.replace('‏', '').trim();
  });
  return result;
};