import {
    TYPE_REQUEST_REPORTS,
    TYPE_RECEIVE_REPORTS,
    TYPE_RESET_REPORTS,
    TYPE_RECEIVE_REPORTS_ERROR
  } from 'data/reports';
  
  export const initialState = {
    reports : [],
    isFetching : false,
    meta : {}
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case TYPE_REQUEST_REPORTS:
        return {
          ...state,
          isFetching: action.isFetching
        };
      case TYPE_RECEIVE_REPORTS:
        return {
          reports: action.reports,
          meta : action.meta,
          isFetching: action.isFetching
        };
        case TYPE_RESET_REPORTS:
          return {
            reports : [],
            isFetching : false,
            meta : {}
          };
      case TYPE_RECEIVE_REPORTS_ERROR:
        return {
          ...state,
          isFetching: action.isFetching,
          error: action.error
        };
      default:
        return state;
    }
  };
  