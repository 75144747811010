import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, InputAdornment, IconButton, Typography, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { useDebounce } from 'utils/hooks';
import useStyles from './ItemConditionsField.styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { yellow } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import { validateCondition } from '../conditions-validation';

const ItemConditionsField = ({ handleChange, value, title = '', tipMessage }) => {
  const classes = useStyles();
  const [conditions, setConditions] = useState(value);
  const [warnings, setWarnings] = useState([]);
  const debouncedConditions = useDebounce(conditions, 500);
  useEffect(() => {
    if (debouncedConditions) {
      handleExternalChange();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedConditions]);

  useEffect(() => {
    setConditions(value || []);
    if (warnings.length < 1)
      setWarnings(Array((value || []).length).join(".").split("."));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const handleAddCondition = () => {
    const newConditions = [...conditions, ''];
    setConditions(newConditions);
    const newWarnings = [...warnings, ''];
    setWarnings(newWarnings);
  };

  const handleChangeInternal = conditionKey => event => {
    const newConditions = conditions.map((condition, index) => {
      if (index !== conditionKey) return condition;
      return event.target.value;
    });
    validateCondition(event.target.value, conditionKey, warnings, setWarnings);
    setConditions(newConditions);
  };

  const handleDeleteCondition = conditionKey => {
    const cleanedConditions = conditions.filter((_, index) => index !== conditionKey);
    setConditions(cleanedConditions);
    const cleanedWarnings = warnings.filter((_, index) => index !== conditionKey);
    setWarnings(cleanedWarnings);
  };

  const handleExternalChange = () => {
    handleChange('conditions')({ target: { value: debouncedConditions } });
  };

  const renderCondition = (_, conditionKey) => {
    return (
      <Box key={conditionKey} mt={1} mb={1}>
        <TextField
          id={`condition-${conditionKey}`}
          type="text"
          value={conditions[conditionKey] || ''}
          onChange={handleChangeInternal(conditionKey)}
          margin="normal"
          fullWidth
          required={true}
          InputProps={{
            classes: {
              root: classes.inputFont,
            },
            endAdornment: (
              <InputAdornment>
                {warnings[conditionKey] ?
                  <Tooltip
                    title={
                      <React.Fragment>
                        {warnings[conditionKey].split("!").map((warning, index) => {
                          if (warning)
                            return (<li key={index}>{warning}</li>);
                          return (<></>);
                        })}
                        <p style={{ margin: 0 }}>{"Please correct and try again."}</p>
                      </React.Fragment>
                    }
                    placement="top-start"
                  >
                    <IconButton>
                      <WarningIcon style={{ color: yellow[700] }} />
                    </IconButton>
                  </Tooltip>
                  : <> </>}
                <IconButton onClick={() => handleDeleteCondition(conditionKey)}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {title ? `${title}` : ''}
        {tipMessage ?
          <Tooltip title={tipMessage} placement="bottom-start">
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip> : ''}
      </Typography>
      {conditions && conditions.map(renderCondition)}
      <Button onClick={() => handleAddCondition()} color="secondary">
        Add Condition
      </Button>
    </div>
  );
};

export default ItemConditionsField;
