import React from 'react';

import TextField from '@material-ui/core/TextField';

const MultiQuestionForm = ({ form, onChange }) => {
  return (
    <>
      <TextField
        id="introHeader"
        label="Intro header"
        type="text"
        value={form.introHeader}
        onChange={onChange('introHeader')}
        margin="normal"
        required
        fullWidth
      />
      <TextField
        id="introBody"
        label="Intro body"
        type="text"
        value={form.introBody}
        onChange={onChange('introBody')}
        margin="normal"
        multiline
        maxRows="5"
        required
        fullWidth
      />
      <TextField
        id="outroHeader"
        label="Outro header"
        type="text"
        value={form.outroHeader}
        onChange={onChange('outroHeader')}
        margin="normal"
        required
        fullWidth
      />
      <TextField
        id="outroBody"
        label="Outro body"
        type="text"
        value={form.outroBody}
        onChange={onChange('outroBody')}
        margin="normal"
        multiline
        maxRows="5"
        required
        fullWidth
      />
    </>
  );
};

export default MultiQuestionForm;
