export const TYPE_REQUEST_MODULES = 'modules/REQUEST_MODULES';
export const TYPE_RECEIVE_MODULES = 'modules/RECEIVE_MODULES';
export const TYPE_RECEIVE_MODULES_CACHED = 'modules/RECEIVE_MODULES-CACHED';
export const TYPE_RECEIVE_MODULES_ERROR = 'modules/RECEIVE_MODULES_ERROR';

export const TYPE_REQUEST_MODULE = 'module/REQUEST_MODULE';
export const TYPE_RECEIVE_MODULE = 'module/RECEIVE_MODULE';
export const TYPE_RECEIVE_MODULE_ERROR = 'module/RECEIVE_MODULE_ERROR';
export const TYPE_UPDATE_MODULE = 'module/UPDATE_MODULE';
export const TYPE_UPDATE_MODULE_SUCCESS = 'module/UPDATE_MODULE_SUCCESS';
export const TYPE_UPDATE_MODULE_ERROR = 'module/UPDATE_MODULE_ERROR';
export const TYPE_CREATE_MODULE = 'module/CREATE_MODULE';
export const TYPE_CREATE_MODULE_SUCCESS = 'module/CREATE_MODULE_SUCCESS';
export const TYPE_CREATE_MODULE_ERROR = 'module/CREATE_MODULE_ERROR';
export const TYPE_RESET_MODULE = 'module/RESET_MODULE';
export const TYPE_GET_CONTENT_SUCCESS = 'module/GET_CONTENT_SUCCESS';
export const TYPE_REQUEST_CONTENT = 'module/REQUEST_CONTENT';
export const TYPE_RECEIVE_CONTENT = 'module/RECEIVE_CONTENT';

export const MODULE_STATE = [
  { value: '0', text: 'Draft (Default)' },
  { value: '20', text: 'Live' },
  { value: '40', text: 'Deactivated' },
];

export const ITEM_TYPE = [
  { value: 'survey', text: 'Survey' },
  { value: 'long', text: 'Long Insight' },
  { value: 'short', text: 'Short Insight' },
  { value: 'pledge', text: 'Pledge' },
];

export const TRIGGERS = [
  { value: 'completed', text: 'Completed (Default)' },
  { value: 'assigned', text: 'Assigned' },
  { value: 'delivered', text: 'Delivered' },
  { value: 'triggered', text: 'Triggered' },
];

export const MODULE_MESSAGE =
  'This field contains the internal-facing name that will be used to identify the program in Aristotle.';

export const ADD_ITEM_MESSAGE = 'Add an existing content to this module.';

export const CONTENT_CONDITIONS_MESSAGE =
  'These are the conditions for transitioning from one piece of content to the next within a module. If left blank, the next piece of content will be triggered without any transition conditions needing to be met.';

export const CONTENT_TESTS_MESSAGE =
  'These are the tests for the Content Transition Conditions. These tests must be filled out if Content Transition Conditions are present.';

export const ADDITIONAL_CONTENT_CONDITIONS = {
  insight: [
    {
      label: 'rated',
      type: 'radio-button',
      options: [
        { label: 'up', value: 'up' },
        { label: 'down', value: 'down' },
        { label: 'rated', value: 'rated' },
        { label: 'read', value: 'read' },
      ],
    },
  ],
  pledge: [
    {
      label: 'opted',
      type: 'radio-button',
      options: [
        { label: 'in', value: 'in' },
        { label: 'out', value: 'out' },
        { label: 'completed', value: 'completed' },
      ],
    },
  ],
  survey: [],
};