import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  container : {
    display : "flex"
  },
  divider : {
    height : "auto"
  }
});
