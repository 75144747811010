import React from 'react';

import Sidebar from 'components/templates/sidebar';
import Pledge from './pledge';
import useStyles from './Pledges.styles';
import Columns from '../columns';

const menuOptions = [
  {
    text: 'Type',
    value: 'type',
    children: [
      {
        text: 'Water',
        value: 'water'
      },
      {
        text: 'Bedtime',
        value: 'bedtime'
      },
      {
        text: 'Steps',
        value: 'steps'
      },
      {
        text: 'Fiber',
        value: 'fiber'
      },
      {
        text: 'Calorie',
        value: 'calorie'
      }
    ]
  },
  {
    text: 'Status',
    value: 'status',
    children: [
      {
        text: 'Draft',
        value: 'draft'
      },
      {
        text: 'Live',
        value: 'live'
      },
      {
        text: 'Deactivated',
        value: 'deactivated'
      }
    ]
  }
];

const Pledges = ({ actions }) => {
  const classes = useStyles();

  const cardBuilder = (pledge, index, searchText) => {
    return (<Pledge key={index} {...{ actions, pledge, index, searchText }} />)
  }

  return (
    <div className={classes.pageContainer}>
      <Sidebar
        title="Pledges"
        mainButtonAction={() => actions.push('/pledges/create')}
        mainButtonText="Create pledge"
      >
      </Sidebar>
      <div className={classes.pledgesContainer}>
        <Columns name="pledges" service={actions.fetchPledges} menuOptions={menuOptions} cardBuilder={cardBuilder} />
      </div>
    </div>
  );
};

export default Pledges;
