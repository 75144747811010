import { fetchReports, clearReports } from 'actions/reports';
import { connect } from 'react-redux';

import { Reports } from 'views';
import { fetchTenantsIfNeeded } from '../../../actions/tenants';

const mapStateToProps = state => ({
  reportsData: state.reports,
  tenants: state.tenants
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getReports: (contentType, status, page, perPage, availableTenants) => {
      dispatch(fetchReports(contentType, status, page, perPage, availableTenants));
    },
    resetReports: () => {
      dispatch(clearReports());
    },
    onMount: () => {
      dispatch(fetchTenantsIfNeeded());
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
