import React, { useState } from 'react';
import ABTestingAndGrouping from 'components/commons/ab-testing-and-grouping';
import { UserCohortException } from 'components/commons/blockly-conditions/utils/index';
import Button from 'components/commons/button';
import Form from 'components/commons/form';
import Select from 'components/commons/select';
import { INSIGHT_CATEGORIES, INSIGHT_STATUSES, INSIGHT_TYPE, PRIORITY_LIMIT_EXCEEDED } from 'data/insights';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { hasDuplicates } from 'utils/commons';
import { openInNewTab } from 'utils/navigation';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { LANGUAGES } from '../../../../data/shared';
import LongInsightForm from '../long-insight-form';
import ShortInsightForm from '../short-insight-form';
import {
  AppTab,
  LabelsAndParamsTab,
  MetadataTab,
  NotificationsTab,
  TracksTab,
  TranslationsTab,
} from '../tabs';
import useStyles from './Form.styles';

const insightTypeForms = {
  0: { component: ShortInsightForm, unnecessaryFields: ['bodyHtml', 'referencesHtml'] },
  1: { component: LongInsightForm, unnecessaryFields: ['linkSource'] },
  2: { component: null, unnecessaryFields: ['bodyHtml', 'referencesHtml', 'linkSource'] },
};

const tabs = [
  LabelsAndParamsTab,
  MetadataTab,
  ABTestingAndGrouping,
  TracksTab,
  NotificationsTab,
  TranslationsTab,
  AppTab,
];

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    <Box p={2}>{children}</Box>
  </Typography>
);

const InsightForm = ({
  facts,
  form,
  setForm,
  handleChange,
  handleCheck,
  handleTenantCheck,
  handleFileDownload,
  handleTemplateFileDownload,
  handleFileUpload,
  handleStatusChange,
  labels,
  onSubmit,
  tenants,
  nonEditableName,
  testResults
}) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const handleSubmit = () => {
    const { unnecessaryFields } = insightTypeForms[form.type];
    unnecessaryFields.forEach((field) => handleChange(field)({ target: { value: '' } }));
    onSubmit();
  };

  const languageOptions = Object.keys(LANGUAGES).map((item) => {
    return { value: item, text: LANGUAGES[item].name };
  });

  const handleTabChange = (e, newValue) => setTabValue(newValue);

  const handleConditions =
    (name) =>
      ({ target: { value } }) => {
        if (value != null) {
          setForm({
            ...form,
            [name]: value
          });
        }
      };

  const handleBlocklyCondition = (code, diff, XML, clear = false) => {
    const newForm = cloneDeep(form);
    if (XML?.childNodes?.length > 0) {
      newForm.visualConditions = new XMLSerializer().serializeToString(XML);
    }

    if (clear) {
      newForm.conditions = [];
      newForm.visualConditions = '<xml></xml>';
    }

    if (code) {
      const newConditions = code;
      var aliasArray = [];
      newConditions.forEach(condition => {
        if (condition.includes('user_cohort')) {
          aliasArray = aliasArray.concat(condition.match(/\?\w+&/g));
        }
      });

      if (hasDuplicates(aliasArray)) {
        throw new UserCohortException();
      }
      newForm.conditions = newConditions;
    }

    setForm(newForm);
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const TypeForm = insightTypeForms[form.type] || '';
  const TypeFormComponent = TypeForm?.component ? (
    <TypeForm.component onChange={handleChange} form={form} />
  ) : null;

  const handleContentPreview = () => {
    openInNewTab(`/previews/content/insights/${form.id}`);
  };

  return (
    <Form className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Select
            label="Category"
            id="category"
            onChange={handleChange('category')}
            value={form.category}
            options={INSIGHT_CATEGORIES}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            label="Type"
            id="type"
            onChange={handleChange('type')}
            value={form.type}
            options={INSIGHT_TYPE}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            style={{ size: '10' }}
            label="Status"
            id="status"
            onChange={handleChange('status')}
            value={form.status}
            options={INSIGHT_STATUSES}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            label="Language"
            id="lang"
            onChange={(e) => handleChange('lang')(e)}
            value={form.lang}
            options={languageOptions}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            id="name"
            label="Name"
            type="text"
            value={form.name}
            onChange={handleChange('name')}
            disabled={nonEditableName}
            InputProps={{
              readOnly: nonEditableName,
            }}
            margin="normal"
            fullWidth
            required
            inputProps={{
              maxLength: 150,
              minLength: 5,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="priority"
            label="Priority"
            type="number"
            value={form.priority}
            onChange={handleChange('priority')}
            margin="normal"
            error={form.priority > 200}
            helperText={form.priority > 200 ? PRIORITY_LIMIT_EXCEEDED : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Value between 0 and 200." placement="top-start">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="ttl"
            label="Time To Live (TTL)"
            type="number"
            value={form.ttl || ''}
            onChange={handleChange('ttl')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Amount of time in days the insight should be displayed."
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="cooldown"
            label="Cooldown"
            type="number"
            value={form.cooldown || ''}
            onChange={handleChange('cooldown')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Amount of time in days between showing the insight for the first time, and showing it again."
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="title"
            label="Title"
            type="text"
            value={form.title}
            onChange={handleChange('title')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="body"
            label="Body"
            type="text"
            value={form.body}
            onChange={handleChange('body')}
            margin="normal"
            multiline
            fullWidth
            required
          />
        </Grid>
      </Grid>
      {!!form.type && TypeFormComponent}
      <Button color="primary" variant="contained" type="submit" size="medium">
        Save
      </Button>
      {form.id && (
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleContentPreview}
          className={classes.previewButton}
        >
          Preview
        </Button>
      )}
      <AppBar className={classes.tabs} elevation={0} position="static">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Labels & Params" {...a11yProps(1)} />
          <Tab label="Metadata" {...a11yProps(0)} />
          <Tab label="AB Testing & Grouping" {...a11yProps(2)} />
          <Tab label="Tracks" {...a11yProps(3)} />
          <Tab label="Notifications" {...a11yProps(4)} />
          <Tab label="Translations" {...a11yProps(5)} />
          <Tab label="App" {...a11yProps(6)} />
        </Tabs>
      </AppBar>
      {tabs.map((TabComponent, index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          <TabComponent
            form={form}
            setForm={setForm}
            handleChange={handleChange}
            handleConditions={handleConditions}
            handleBlocklyCondition={handleBlocklyCondition}
            handleCheck={handleCheck}
            handleTenantCheck={handleTenantCheck}
            handleFileDownload={handleFileDownload}
            handleTemplateFileDownload={handleTemplateFileDownload}
            handleFileUpload={handleFileUpload}
            handleStatusChange={handleStatusChange}
            facts={facts.facts}
            labels={labels.labels}
            tenants={tenants.tenants}
            testResults={testResults}
            type="insight"
          />
        </TabPanel>
      ))}
    </Form>
  );
};

InsightForm.propTypes = {
  facts: PropTypes.object,
  form: PropTypes.object,
  handleChange: PropTypes.func,
  handleCheck: PropTypes.func,
  handleTenantCheck: PropTypes.func,
  handleFileDownload: PropTypes.func,
  handleTemplateFileDownload: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleStatusChange: PropTypes.func,
  labels: PropTypes.object,
  onSubmit: PropTypes.func,
  tenants: PropTypes.object,
  testResults: PropTypes.array,
  nonEditableName: PropTypes.bool
};

export default InsightForm;
