import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  pageContainer: {
    marginTop: theme.spacing(8),
    height: '100%',
    width: '100%',
    overflowX: 'scroll',
    padding: theme.spacing(2)
  },
  dataGrid: {
    width: "100%",
    height: "100%"
  },
  dataGridContainer: {
    marginBottom: 100
  },
  dataGridContainerHidden: {
    display: "none"
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: 12
  },
  itemsContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  toolbarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  tableIcon: {
    height: "100%",
    color: "white"
  },
  columnHeader:{
    padding: 0,
  },
  circularProgress: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  columnTextWrapper: {
    overflow: "hidden",
    textOverflow: 'ellipsis'
  }
}));
