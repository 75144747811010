import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Select from 'components/commons/select';
import MultipleChoiceOptions from 'components/views/surveys/multiple-choice-options';
import QuestionInput from 'components/views/surveys/question-input';
import SingleAnswerOptions from 'components/views/surveys/single-answer-options';
import { SURVEY_QUESTION_TYPES } from 'data/surveys';
import { constant, isEmpty, update } from 'lodash';
import { useDebounce } from 'utils/hooks';
import { openInNewTab } from 'utils/navigation';

import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  InfoOutlined as InfoIcon,
  MoreVert as MoreIcon
} from '@material-ui/icons';
import PhoneIphone from '@material-ui/icons/PhoneIphone';

import useStyles from './Question.styles';
import QuestionOptions from './options';

const questionTypeForms = {
  0: { component: MultipleChoiceOptions },
  1: { component: SingleAnswerOptions },
  3: { component: MultipleChoiceOptions },
};

const Question = ({
  onClick,
  onChange,
  onDelete,
  initialValue,
  isSelected,
  nextQuestion,
  onChangePosition,
  showOnlyInput,
  isDragging,
  surveyId,
}) => {
  const classes = useStyles();
  const [hidden, setHidden] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [question, setQuestion] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const debouncedQuestion = useDebounce(question, 500);

  useEffect(() => {
    if (!isEmpty(initialValue) && !gotInitialValue) {
      setQuestion(initialValue);
      setGotInitialValue(true);
    }
  }, [initialValue, gotInitialValue]);

  useEffect(() => {
    if (!isEmpty(debouncedQuestion)) {
      onChange(debouncedQuestion);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuestion]);
  useEffect(() => {
    setHidden(!isSelected);
    if (!isSelected) setSelectedOption(null);
  }, [isSelected]);

  const handleChange =
    (name) =>
      ({ target: { value } }) => {
        let pos = question.position;
        setQuestion({ ...update(question, name, constant(value)) });
        if (name === 'position') onChangePosition(question.name, pos, value);
      };

  const handleTypeChange = ({ target: { value } }) =>
    setQuestion({
      ...update(question, 'type', constant(value)),
      options: [
        {
          inputType: 'default',
          nextQuestion,
          position: 1,
          text: 'Option text',
          value: '',
        },
      ],
    });

  const handleOpenDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenDialog(true);
  };

  const handleSelectOption = (optionKey) => () => setSelectedOption(optionKey);

  const QuestionTypeForm = questionTypeForms[question.type] || '';

  const contractedQuestion = (
    <Paper
      className={classNames(classes.container, {
        [classes.selected]: isSelected,
      })}
      onClick={onClick}
      elevation={isSelected ? 4 : 0}
      square
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <QuestionInput
            id="question-text"
            label="Question text"
            type="text"
            value={question.text}
            onChange={handleChange('text')}
            margin="normal"
            multiline
            maxRows="3"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TextField
            label="Question name"
            type="text"
            value={question.name || ''}
            onChange={handleChange('name')}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            id="type"
            label="Question type"
            onChange={handleTypeChange}
            value={question.type}
            options={SURVEY_QUESTION_TYPES}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  );

  if (isDragging) {
    return contractedQuestion;
  }

  if (showOnlyInput && !isSelected) {
    return contractedQuestion;
  }

  if (!showOnlyInput && isSelected) {
    return (
      <Paper
        className={classNames(classes.container, {
          [classes.selected]: isSelected,
        })}
        onClick={onClick}
        elevation={isSelected ? 4 : 0}
        square
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <QuestionInput
              id="question-text"
              label="Question text"
              type="text"
              value={question.text}
              onChange={handleChange('text')}
              margin="normal"
              multiline
              maxRows="3"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              label="Question name"
              type="text"
              value={question.name || ''}
              onChange={handleChange('name')}
              margin="normal"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select
              id="type"
              label="Question type"
              onChange={handleTypeChange}
              value={question.type}
              options={SURVEY_QUESTION_TYPES}
              margin="normal"
              required
              fullWidth
            />
          </Grid>
        </Grid>
        {!!question.type && QuestionTypeForm && (
          <QuestionTypeForm.component
            onChange={handleChange}
            options={question.options}
            nextQuestion={nextQuestion}
            onClickOption={handleSelectOption}
            selectedOption={selectedOption}
            hidden={hidden}
          />
        )}
        {!hidden && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <TextField
                  margin="normal"
                  label="Default next question"
                  type="text"
                  value={question.defaultNextQuestion}
                  onChange={handleChange('defaultNextQuestion')}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title="Can be set to Outro to direct to outro instead of another question."
                          placement="top-start"
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <div className={classes.questionActionsContainer}>
              {!!onDelete && (
                <Tooltip title="Delete question">
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Additional settings">
                <IconButton>
                  <MoreIcon onClick={() => setOpenDialog(true)} />
                </IconButton>
              </Tooltip>
            </div>
          </>
        )}
        {/* <QuestionOptionsDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onChange={handleChange}
          question={question}
        /> */}
      </Paper>
    );
  }

  if (isSelected) {
    return (
      <Paper
        className={classNames(classes.container, {
          [classes.selected]: isSelected,
        })}
        onClick={onClick}
        elevation={isSelected ? 4 : 0}
        square
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <QuestionInput
              id="question-text"
              label="Question text"
              type="text"
              value={question.text}
              onChange={handleChange('text')}
              margin="normal"
              multiline
              maxRows="3"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              label="Question name"
              type="text"
              value={question.name || ''}
              onChange={handleChange('name')}
              margin="normal"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select
              id="type"
              label="Question type"
              onChange={handleTypeChange}
              value={question.type}
              options={SURVEY_QUESTION_TYPES}
              margin="normal"
              required
              fullWidth
            />
          </Grid>
        </Grid>
        {!!question.type && QuestionTypeForm && (
          <QuestionTypeForm.component
            onChange={handleChange}
            options={question.options}
            nextQuestion={nextQuestion}
            onClickOption={handleSelectOption}
            selectedOption={selectedOption}
            hidden={hidden}
          />
        )}
        {!hidden && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <TextField
                  margin="normal"
                  label="Default next question"
                  type="text"
                  value={question.defaultNextQuestion}
                  onChange={handleChange('defaultNextQuestion')}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title="Can be set to Outro to direct to outro instead of another question."
                          placement="top-start"
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <div className={classes.questionActionsContainer}>
              <Tooltip title="Question preview">
                <IconButton
                  onClick={() =>
                    openInNewTab(`/previews/content/surveys/${surveyId}/question/${question.id}`)
                  }
                >
                  <PhoneIphone />
                </IconButton>
              </Tooltip>
              {!!onDelete && (
                <Tooltip title="Delete question">
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Additional settings">
                <IconButton onClick={handleOpenDialog}>
                  <MoreIcon />
                </IconButton>
              </Tooltip>
            </div>
          </>
        )}
        <QuestionOptions
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onChange={handleChange}
          question={question}
        />
      </Paper>
    );
  }
  return contractedQuestion;
};

export default Question;