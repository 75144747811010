import axios from 'axios';

const GATEWAY_ENDPOINT = process.env.REACT_APP_HOST_URL;
const USER_V2_ENDPOINT = '/user/api/v0.2';


class UserAPI {
  async azureLogin(token) {
    return axios
      .post(
        `${GATEWAY_ENDPOINT + USER_V2_ENDPOINT}/users/@me/tokens?prms=jhh_admin`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(response => response.data);
  }
}
  export default UserAPI;