/**
 * Open a new window in the browser
 *
 *
 * @param {string} url
 */
export function openInNewTab(url) {
  const newWindow = window.open(url, '_blank',
      'toolbar=yes,resizable=yes,width=900,height=900'
    );
    if (newWindow) newWindow.opener = null;
}